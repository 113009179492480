import React from 'react'
import TotalSection1 from './TotalSection/TotalSection1'
import TotalSection2 from './TotalSection/TotalSection2'
import TotalSection3 from './TotalSection/TotalSection3'
import TotalSection4 from './TotalSection/TotalSection4'

const TotalWater = () => {
  return (
    <div>
        <TotalSection1/>
        <TotalSection2/>
        <TotalSection3/>
        <TotalSection4/>
    </div>
  )
}

export default TotalWater