import React from 'react'
import '../../Solutions/Solution.css'

const TotalSection1 = () => {
  return (
    <div>
        <div className='innerpageheroback'>
        <h1 className='solutionherohead1'>Total Water Management</h1>
        </div>
        <div className='containers1'>
        <p className='solinnertext'>
  Water is at the core of sustainable development given that it is critical for socio-economic development, healthy ecosystems, and human survival. India is experiencing increasing variability in availability, with differentials translating into unequal distribution and access.
  Total water management is a comprehensive strategy aimed at managing water resources efficiently and sustainably. It ensures water security which involves safeguarding the availability and access to clean water for all, preserving ecosystems, and adhering to environmental regulations. Successful water management leads to improved ESG ratings and enhances an organization&apos;s reputation. It also contributes to environmental conservation and corporate citizenship, aiming for a more sustainable future.
</p>
        <div className='d-flex justify-content-center'><h2 className="homeabouthead mt-3 ">Components of <span className="homeabouthead1"> Total Water Management</span></h2></div>
    </div>
    </div>
  )
}

export default TotalSection1