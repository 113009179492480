import React from 'react'
import InfraSection1 from './InfraSection/InfraSection1'
import HomeClient from '../Home/HomeSection/HomeClient'
import HomeClient2 from '../Home/HomeSection/HomeClient2'

const Infra = () => {
  return (
    <div>
        <InfraSection1/>
        <HomeClient/>
        <HomeClient2/>
    </div>
  )
}

export default Infra