import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import "../../../App.css"


const SlideContent = () => (
    <div className="herosec">
      <div className="sliderback">
        <div className="container">
          <div className="d-flex">
            <h1 className="herohead">Clean Water</h1>
            <img src="/droplet.webp" className="dropletimg" alt="drop" />
          </div>
          <h2 className="herohead2">Begins Here</h2>
          <p className="hometext">
            Wapp as a total water management company offers a full range of products, technologies, solutions and services. We design, consult, construct, upgrade, operate and maintain, water and wastewater treatment systems for our customers.
          </p>
          <a href="https://wa.me/message/AJ7APJJTR5AXJ1" style={{ textDecoration: 'none' }}>
            <div className='d-flex justify-content-center align-items-center getinbutt'>
              Get In Touch
              <FontAwesomeIcon icon={faArrowRight} className='arrow' />
            </div>
          </a>
          <div className='row slidertext1 mt-4'>
            <div className='col-md-6 col-sm-12'>
              <p className='slidertext'>marketing@wappsys.com | www.wappsys.com</p>
            </div>
            <div className='col-md-6 col-sm-12'>
              <p className='slidertext'>Call us - 97178 40888</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  
  const SlideContent1 = () => (
    <div className="video-container">
      <video  className='video_hero' autoPlay loop muted>
        <source src='/herosection_video.mp4' type='video/mp4'/>
      </video>
      <div className="sliderbackvideo">
        <div className="container">
          <div className="d-flex">
            <h1 className="herohead">Clean Water</h1>
            <img  src="/droplet.webp" className="dropletimg" alt="drop" />
          </div>
          <h2 className="herohead2">Begins Here</h2>
          <p className="hometext">
            Wapp as a total water management company offers a full range of products, technologies, solutions and services. We design, consult, construct, upgrade, operate and maintain, water and wastewater treatment systems for our customers.
          </p>
          <a href="https://wa.me/message/AJ7APJJTR5AXJ1" style={{ textDecoration: 'none' }}>
            <div className='d-flex justify-content-center align-items-center getinbutt'>
              Get In Touch
              <FontAwesomeIcon icon={faArrowRight} className='arrow' />
            </div>
          </a>
          <div className='row slidertext1 mt-4'>
            <div className='col-md-6 col-sm-12'>
              <p className='slidertext'>marketing@wappsys.com | www.wappsys.com</p>
            </div>
            <div className='col-md-6 col-sm-12'>
              <p className='slidertext'>Call us - 97178 40888</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

const HeroSection = () => {
  return (
    <>
      <div className='desk_swip'>
        <Swiper 
          modules={[Navigation]} 
          loop={true}
          className="mySwiper"
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          lazy="true"
          autoplay={{
            delay: 3000, 
            disableOnInteraction: false, 
          }}
        >
          <SwiperSlide><SlideContent1/></SwiperSlide>
          <SwiperSlide><SlideContent /></SwiperSlide>
        </Swiper>
        <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div>
      </div>
      <div className='mob_div'>
        <SlideContent/>
      </div>
    </>
  )
}

export default HeroSection