import React from 'react'
import { useState } from 'react';
import '../Product.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGreaterThan } from '@fortawesome/free-solid-svg-icons';

const ServiceItem = ({ imgSrc, title, boldtext1, boldtext2, boldtext3, boldtext4, boldtext5, boldtext6, text1, text2, text3, text4, text5, text6, imgFirst }) => {
    const [showMore, setShowMore] = useState(false);
  
    const handleReadMore = () => {
      setShowMore(!showMore);
    };
  
    return (
      <div className='mt-4 '>
        {imgFirst ? (
          <div className='row'>
            <div className='col-md-7 col-sm-12'>
              <div className='service_imgdiv'>
                <img src={imgSrc} className='productimg' alt=''/>
              </div>
            </div>
            <div className='col-md-5 col-sm-12'>
              <div className='relate'>
                <h2 className='producthead'>{title}</h2>
                <ul>
                  <li className='boldproducttext'>{boldtext1}<p className='producttext'>{text1}</p></li>
                  <li className='boldproducttext'>{boldtext2}<p className='producttext'>{text2}</p></li>
                  <li className='boldproducttext'>{boldtext3}<p className='producttext'>{text3}</p></li>
                  {showMore && 
                    <>
                      <li className='boldproducttext'>{boldtext4}<p className='producttext'>{text4}</p></li>
                      <li className='boldproducttext'>{boldtext5}<p className='producttext'>{text5}</p></li>
                      <li className='boldproducttext'>{boldtext6}<p className='producttext'>{text6}</p></li>
                    </>
                  }
                </ul>
                <button className="read_btn" onClick={handleReadMore}>
                  {showMore ? "Read Less" : "Read More"}<FontAwesomeIcon className="more-icon ms-1" icon={faGreaterThan}/>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="service_rev row">
            <div className='col-md-5 col-sm-12'>
              <div className=''>
                <h2 className='producthead'>{title}</h2>
                <ul>
                  <li className='boldproducttext'>{boldtext1}<p className='producttext'>{text1}</p></li>
                  <li className='boldproducttext'>{boldtext2}<p className='producttext'>{text2}</p></li>
                  <li className='boldproducttext'>{boldtext3}<p className='producttext'>{text3}</p></li>
                  {showMore && 
                    <>
                      <li className='boldproducttext'>{boldtext4}<p className='producttext'>{text4}</p></li>
                      <li className='boldproducttext'>{boldtext5}<p className='producttext'>{text5}</p></li>
                      <li className='boldproducttext'>{boldtext6}<p className='producttext'>{text6}</p></li>
                    </>
                  }
                </ul>
                <button className="read_btn" onClick={handleReadMore}>
                  {showMore ? "Read Less" : "Read More"}<FontAwesomeIcon className="more-icon ms-1" icon={faGreaterThan}/>
                </button>
              </div>
            </div>
            <div className='col-md-7 col-sm-12 '>
              <div className='service_imgdiv'>
                <img src={imgSrc} className='productimg' alt='prd'/>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

const ServiceSection = () => {
    const services = [
        {
          imgSrc: '/serviceimg1.webp',
          title: 'Consulting & Assessment',
          boldtext1: 'Comprehensive Evaluations:',
          text1: 'Conducting thorough assessments of client water requirements and resource consumption to ensure compliance with environmental regulations.',
          boldtext2: 'Tailored Water Management Plans:',
          text2: 'Designing customized plans to optimize water usage efficiency according to specific client needs.',
          boldtext3: 'Risk Identification and Mitigation:',
          text3: 'Identifying potential water-related risks through rigorous assessment and developing strategic mitigation strategies aligned with ESG principles and SDGs.',
          boldtext4: 'Economic Implications Analysis:',
          text4: 'Evaluating economic factors, including cost analysis, to provide clients with insights into investment returns.',
          boldtext5: 'Holistic Approach:',
          text5: 'Integrating environmental sustainability, operational efficiency, and resilience to position clients for social responsibility and economic viability.',
          boldtext6: 'Benefits:',
          text6: 'Safeguarding environmental sustainability, enhancing operational efficiency, and enabling clients to thrive in a socially responsible and economically viable manner.',
          imgFirst: true,
        },
        {
          imgSrc: '/serviceimg2.webp',
          title: 'Smart & Green Technologies',
          boldtext1: 'Tailored Solutions:',
          text1: 'Crafting a spectrum of solutions meticulously designed to meet the specific needs of individual customers.',
          boldtext2: 'Pre-Packaged Products:',
          text2: 'Offering ready-made solutions for clients seeking immediate implementation without customization.',
          boldtext3: 'Strategic Alliances:',
          text3: 'Forming partnerships with top technology providers like DigitalPaani, Scalene, Pristine and CII.',
          boldtext4: 'Technological Enhancement:',
          text4: 'Collaborations aimed at boosting technological capabilities and staying at the forefront of innovations.',
          boldtext5: 'High-Quality Deliverables:',
          text5: 'Strengthening the capacity to provide exceptional services and products.',
          boldtext6: 'Preferred Choice:',
          text6: 'Solidifying the company’s position as the top choice for clients in need of bespoke and innovative solutions.',
          imgFirst: false,
        },
        {
          imgSrc: '/serviceimg3.webp',
          title: 'Operation & Maintenance',
          boldtext1: 'Ongoing Support and Maintenance:',
          text1: 'Providing continuous operational excellence through regular support and maintenance services.',
          boldtext2: 'Proactive Monitoring:',
          text2: 'Utilizing DigitalPaani-driven methodologies to detect and address potential issues preemptively, reducing downtime and boosting productivity.',
          boldtext3: 'Regular System Checks:',
          text3: 'Conducting routine inspections and adjustments to maintain peak performance and ensure smooth operations for clients.',
          boldtext4: 'Preventive Measures:',
          text4: 'Prioritizing proactive approaches to minimize disruptions and enhance system reliability and longevity.',
          boldtext5: 'Commitment to Excellence:',
          text5: 'Demonstrating dedication to delivering exceptional service and value, fostering long-term partnerships based on trust and reliability.',
          boldtext6: 'Benefits:',
          text6: 'Maximizing productivity, reducing downtime, enhancing system reliability, and fostering long-term client relationships.',
          imgFirst: true,
        },
        {
          imgSrc: '/serviceimg4.webp',
          title: 'Water Neutrality roadmap',
          boldtext1: 'Wastewater treatment & reuse:',
          text1: 'Wastewater treatment and its reuse can significantly reduce freshwater demand by providing an alternative water source for irrigation, industrial processes, and even potable use. This reduces the extraction pressure on natural freshwater bodies and ensures sustainable water management.',
          boldtext2: 'Minimize losses:',
          text2: 'Know the losses and minimize them using DigitalPaani (Automation, real-time monitoring & dashboard) Improve your wastewater treatment plant operations & maximize reuse.',
          boldtext3: 'Zero Liquid Discharge:',
          text3: 'Achieve zero liquid discharge/ do demand-side management based on targeted benchmarking for each application. We have multiple technologies/solutions to minimize overall losses and achieve ZLD.',
          boldtext4: 'Neutralize freshwater demand:',
          text4: 'Neutralize the freshwater demand through rainwater harvesting/sourcing wastewater. Rainwater harvesting captures and stores rainwater for various uses, reducing the reliance on freshwater sources. This practice helps neutralize freshwater demand by supplementing water supply for domestic, agricultural, and industrial needs. (Inside/outside the fence).',
          boldtext5: 'Rainwater harvesting outside the fence:',
          text5: 'Rainwater harvesting outside the fence means collecting rainwater from areas far from the local water source. This stored rainwater can be used to help reduce the need for local freshwater.',
          imgFirst: false,
        },
      ];
    
  return (
    <div className='containers1 mt-4 mt-md-0'>
      <h2 className="homeabouthead">Our <span className="homeabouthead1">Services</span></h2>
      {services.map((service, index) => (
        <ServiceItem
          key={index}
          imgSrc={service.imgSrc}
          title={service.title}
          boldtext1={service.boldtext1}
          boldtext2={service.boldtext2}
          boldtext3={service.boldtext3}
          boldtext4={service.boldtext4}
          boldtext5={service.boldtext5}
          boldtext6={service.boldtext6}
          text1={service.text1}
          text2={service.text2}
          text3={service.text3}
          text4={service.text4}
          text5={service.text5}
          text6={service.text6}
          imgFirst={service.imgFirst}
        />
      ))}
    </div>
  )
}

export default ServiceSection