import React from 'react'
import '../About.css'

const AboutSection2 = () => {
  return (
    <div className='containers1 mt-5'>
    <div className='circlecontainer'>
    <div className='circleitem'>
      <div className='circle-center'>
    <h1 className='abouthead1'>Our <span className='colouredhead'>Mission</span></h1>
    <p className='abouttext2'>To help customers make the most of their systems with modern water tech, top-notch solutions, and great service. Continuously innovating to meet evolving water needs and exceed customer expectations.</p>
    </div>
    </div>
    <div className='circleitem1'>
    <div className='circle-center'>
    <h1 className='abouthead1'>Our <span className='colouredhead'>Vision</span></h1>
    <p className='abouttext2'>Our goal is to lead the people in water and wastewater management solutions, driven by our core values of Impact, Sustainability, and Innovation. We&apos;re committed to offering integrated, green solutions aligned with WATER-INNO-VISION, focusing on Water. </p>
    </div>
    </div>
    <div className='circleitem2'>
      <div className='circle-center'>
    <h1 className='abouthead1'>Our <span className='colouredhead'>commitment</span></h1>
    <p className='abouttext2'>We&apos;re dedicated to empowering our clients with innovative water management strategies, tailored to their unique needs and challenges, while ensuring we exceed regulatory standards and safeguard the environment for future generations.</p>
    </div>
    </div>
    </div>
  </div>
  )
}

export default AboutSection2