import { useState } from 'react';
import {Link} from 'react-router-dom';
import '../AdminPanel.css'


const Sidebar = () => {
    const [activeLink, setActiveLink] = useState(null);

    const handleLinkClick = (link) => {
      setActiveLink(link);
    };
  return (
    <>
   
    <div className='side'>
    <div className='sticky_sidebar'>
        <Link to='/Blogpanel' className='no-underline '>
          <div  className={`sidelinks ${activeLink === 'Blogpanel' ? 'active1' : ''}`} onClick={() => handleLinkClick('Blogpanel')}>BLOGS</div>
        </Link>
        <Link to='/Productpanel' className='no-underline'>
          <div className={`sidelinks ${activeLink === 'Productpanel' ? 'active1' : ''}`} onClick={() => handleLinkClick('Productpanel')}>PRODUCT</div>
        </Link>
        <Link to='/Leadpanel' className='no-underline'>
          <div className={`sidelinks ${activeLink === 'Leadpanel' ? 'active1' : ''}`} onClick={() => handleLinkClick('Leadpanel')}>LEADS</div>
        </Link>
    </div>
   </div>
  

    <div className='sideabove d-flex justify-content-between align-items-center'>
    <img className='admin_logo' src='/wapp_logo.webp' alt='hh'/>
      <h1 className='sidehead'>Admin Panel</h1>
      <p className='sidetext'>Wappsys</p>
    </div>
  </>
  )
}

export default Sidebar