import React from 'react'
import '../../Solutions/Solution.css'

const TreatmentSection1 = () => {
  return (
    <>
    <div className='treatmentback'>
  <h1 className='solutionherohead1'>Effluent Treatment Plant</h1>
    </div>
     <div className='containers1'>
     <p className='solinnertext'>An Effluent Treatment Plant (ETP) is a crucial component of modern industrial infrastructure designed to manage and treat wastewater produced during industrial processes. It plays a pivotal role in ensuring environmental sustainability and regulatory compliance by effectively treating and purifying industrial wastewater before its discharge into the environment.
</p>
     <div className='d-flex justify-content-center'><h2 className="homeabouthead mt-3 ">Key <span className="homeabouthead1">Components</span></h2></div>
 </div>
 </>
  )
}

export default TreatmentSection1