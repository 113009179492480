import React from 'react'
import UrbanHero from './UrbanSection/UrbanHero'
import UrbanSection1 from './UrbanSection/UrbanSection1'
import UrbanSection2 from './UrbanSection/UrbanSection2'
import HomeClient from '../Home/HomeSection/HomeClient'
import HomeClient2 from '../Home/HomeSection/HomeClient2'

const Urban = () => {
  return (
    <div>
        <UrbanHero/>
        <div className='containers1'>
      <UrbanSection1/>
      <UrbanSection2/>
        </div>
        <HomeClient/>
        <HomeClient2/>
    </div>
  )
}

export default Urban