import React from 'react'
import Sidebar from '../AdminPanel/AdminSection/Sidebar'

const AdminPanel = () => {
  return (
    <div>
<Sidebar/>
    </div>
  )
}

export default AdminPanel