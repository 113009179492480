import { useState, useEffect, useRef } from 'react';
import { Link, useParams, useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';


const BlogPage = () => {
    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [latestPosts, setLatestPosts] = useState([]);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({ title: '', content: '' });

  
  const openModal = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
    setSelectedItem({ title: '', content: '' });
    resetForm();
  };
  const resetForm = () => {
    setName('');
    setCompany('');
    setPhone('');
    setEmail('');
    setMessage('');
  };

  const contentRefs = {
    description: useRef(null),
    content1: useRef(null),
    content2: useRef(null),
    content3: useRef(null),
    content4: useRef(null),
    content5: useRef(null),
  };

  const handleSearch = (event) => {
    const term = event.target.value;
    if (event.key === 'Enter') {
      const params = new URLSearchParams(searchParams);
      if (term) {
        params.set('query', term);
      } else {
        params.delete('query');
      }
      setSearchParams(params);
      navigate(`${window.location.pathname}?${params.toString()}`);
    }
  };



  const fetchPosts = async (searchTerm = '') => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/posts${searchTerm ? `?query=${searchTerm}` : ''}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const matchingPost = data.find(post => post._id === id);
      if (matchingPost) {
        setPost(matchingPost);
        setLatestPosts(data.slice(-2));
        setRelatedPosts(data.slice(0, 3));
      } else {
        setError(new Error('Post not found'));
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  
  useEffect(() => {
    async function fetchData() {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/contacts`; 
        const response = await axios.get(apiUrl);
        // Handle the fetched data if needed
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  
    fetchData();
  }, []);
  
  async function createNewContact(ev) {
    ev.preventDefault();

    const data = {
      name,
      company,
      phone,
      email,
      message
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/contacts`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 200) {
        openModal(details.message);
      }
    } catch (error) {
      console.error('Error creating contact:', error);
    }
  }

  const details = {
    message: {
      title: 'Thanks for the Query',
      content: 'We will revert you back as soon as possible.'
    }
  };
  
  useEffect(() => {
    const query = searchParams.get('query') || '';
    fetchPosts(query);
  }, [id, searchParams]);

  useEffect(() => {
    const query = searchParams.get('query') || '';
    if (query && post) {
      const keys = Object.keys(contentRefs);
      let timeoutId;
      for (const key of keys) {
        if (post[key]?.toLowerCase().includes(query.toLowerCase()) && contentRefs[key].current) {
          timeoutId = setTimeout(() => {
            contentRefs[key].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }, 0);
          break;
        }
      }
      return () => clearTimeout(timeoutId);
    }
  }, [post, searchParams, contentRefs]);
  
  
  const formattedDate = post
    ? new Date(post.createdAt).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    : '';

  if (loading) {
    return <div>Loading...</div>; // or a spinner component
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!post) {
    return <div>Post not found</div>; // handle case when post is null
  }

  return (
    <>
    <div className='blog_hero '>
          {post.files && post.files.length > 0 && (
            <div className="blogpage_hero_img">
              <img  src={`${process.env.REACT_APP_API_URL}/uploads/${post.files[0]}`} alt="cap" className="blog_heroimg" />
              <div className="overlay_text">
                <h2 className='blog_hero_title'>{post.name}</h2>
                <div className='d-flex mt-4 '>
                <p className='hero_stxt'>{formattedDate}</p>
                  <p className='hero_stxt ms-4'>By Wappsys</p>
                  <p className='hero_stxt ms-4'>Water Management</p>
                </div>
              </div>
            </div>
          )}
      </div>
    <div className='containers1 mt-4'>
      <div className='row'>
        <div className='col-md-8 col-sm-12 page_section_1'>
          {post.files && post.files.length > 1 && (
            <div className="blogpage_img1">
              <img src={`${process.env.REACT_APP_API_URL}/uploads/${post.files[1]}`} alt="cap" className="blog_img1" />
            </div>
          )}
         
          <div ref={contentRefs.content1} className='blogpage_content mt-3' dangerouslySetInnerHTML={{ __html: post.content1 }} />
        
          <div className='row mt-4 '>
          <div ref={contentRefs.content2} className='blogpage_content mt-2 mb-4' dangerouslySetInnerHTML={{ __html: post.content2 }} />
            <div className='col-md-3 col-sm-12'>
              {post.files && post.files.length > 2 && (
                <div className="blogpage_img2">
                  <img  src={`${process.env.REACT_APP_API_URL}/uploads/${post.files[2]}`} alt="cap" className="blog_img2" />
                </div>
              )}
            </div>
            <div className='col-md-9 col-sm-12 d-flex align-items-center justify-content-center'>
              <div ref={contentRefs.content3} className='blogpage_points' dangerouslySetInnerHTML={{ __html: post.content3 }} />
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-md-3 col-sm-12'>
              {post.files && post.files.length > 3 && (
                <div className="blogpage_img2">
                  <img  src={`${process.env.REACT_APP_API_URL}/uploads/${post.files[3]}`} alt="cap" className="blog_img2" />
                </div>
              )}
            </div>
            <div className='col-md-9 col-sm-12 d-flex align-items-center justify-content-center'>
              <div ref={contentRefs.content4} className='blogpage_points' dangerouslySetInnerHTML={{ __html: post.content4 }} />
            </div>
          </div>
          <div className='row mt-5'>
            <div className='col-12'>
              {post.files && post.files.length > 4 && (
                <div className="blogpage_img1">
                  <img src={`${process.env.REACT_APP_API_URL}/uploads/${post.files[4]}`} alt="cap" className="blog_img1" />
                </div>
              )}
              <div ref={contentRefs.content5} className='blogpage_content mt-3' dangerouslySetInnerHTML={{ __html: post.content5 }} />
            </div>
          </div>

          <h4 className='related_title mt-5'> RELATED POSTS</h4>
          <div className='relatedpost_container mt-2'>
            {relatedPosts.map((post, index) => (
              
              <div key={index} className='related-card'>
                {post.files && post.files.length > 0 && (
                  <div className="relatedpost_imgdiv">
                    <img src={`${process.env.REACT_APP_API_URL}/uploads/${post.files[4]}`} alt="cap" className="related_img" />
                  </div>
                )}
                 <div className='d-flex mt-3'>
                <p className='latest_stxt'>{formattedDate}</p> 
                <p className='latest_stxt ms-2'>By Wappsys</p>
                </div> 
                <h4 className='relatedpost_head'>{post.name}</h4>
                <div className='relatedpost_text ' dangerouslySetInnerHTML={{ __html: post.content1 }} />
                <Link to={`/blogpage/${post._id}`}>
                  <button className='readpost_btn mt-3'>Read Full Post</button>
                </Link>
              </div>
            ))}
          </div>
          <div className='comment_container'>
            <h4 className='comment_title'>Drop a Comment</h4>
            <form className='inputfield_container'  onSubmit={createNewContact}>
              <div className='d-md-flex justify-content-between'>
                <input className='name_input_field mt-4' type='name' placeholder='Name' value={name} onChange={ev => setName(ev.target.value)} />
                <input className='name_input_field mt-4' type='email' placeholder='Email' value={email} onChange={ev => setEmail(ev.target.value)} />
              </div>
              <div className='d-md-flex justify-content-between'>
              <input type="tel" placeholder="Phone"  className='name_input_field mt-4' value={phone} onChange={ev => setPhone(ev.target.value)} />
              <input className='name_input_field mt-4' type='text' placeholder='Website' value={company} onChange={ev => setCompany(ev.target.value)}  />
              </div>
              <textarea className='message_input_field mt-4' type='textarea' placeholder='Enter Your Comment here' value={message} onChange={ev => setMessage(ev.target.value)}  />
              <button className='comment_btn mt-4'  onClick={() => openModal(details.message)}>Post Comment</button>
            </form>
          </div>
          {showModal && (
      <div className='modal'>
        <div className='modal-content'>
          <span className='close' onClick={closeModal}>&times;</span>
          <h2 className='message-head'>{selectedItem.title}</h2>
          <p className='message_text'>{selectedItem.content}</p>
        </div>
      </div>
    )}
        </div>

        <div className='col-4 page_section_2'>
          <div className='sticky_div'>
            <div className="search_icon_div">
              <input
                className="searchinput"
                type="text"
                placeholder="Enter keyword here"
                onKeyDown={handleSearch}
                defaultValue={searchParams.get('query')?.toString()}
              />
            </div>
            <div className='wapp_cont'>
              <p className='blog_des'>{post.description}</p>
            </div>
            <h4 className='latest_head'>LATEST POSTS</h4>
            {latestPosts.map((post, index) => (
              <Link  to={`/blogpage/${post._id}`} key={post.id}>
              <div key={index} className='row mt-4'>
                <div className='col-4'>
                  {post.files && post.files.length > 0 && (
                    <div className="blogpage_img3">
                      <img  src={`${process.env.REACT_APP_API_URL}/uploads/${post.files[0]}`} alt="cap" className="blog_img3" />
                    </div>
                  )}
                </div>
                <div className='col-8'>
                  <div className='d-flex'>
                <p className='latest_stxt'>{formattedDate}</p> 
                <p className='latest_stxt ms-2'>By Wappsys</p>
                </div> 
                  <p className='latest_cont'>{post.name}</p>
                </div>
              </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default BlogPage