import React from 'react'
import IndustryHero from './IndustrySection/IndustryHero'
import HomeClient2 from '../Home/HomeSection/HomeClient2'
import HomeClient from '../Home/HomeSection/HomeClient'
import IndustrySection1 from './IndustrySection/IndustrySection1'
import IndustrySection2 from './IndustrySection/IndustrySection2'
import IndustrySection3 from './IndustrySection/IndustrySection3'

const Industry = () => {
  return (
    <div>
        <IndustryHero/>
        <div className='containers1'>
         <IndustrySection1/>
         <IndustrySection2/>
         <IndustrySection3/>
        </div>
        <HomeClient/>
        <HomeClient2/>
    </div>
  )
}

export default Industry