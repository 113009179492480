import { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import '../AdminPanel.css'


const Panel2 = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [heading2, setHeading2] = useState('');
    const [content1, setContent1] = useState('');
    const [content2, setContent2] = useState('');
    const [box1, setBox1] = useState('');
    const [box2, setBox2] = useState('');
    const [box3, setBox3] = useState('');
    const [box4, setBox4] = useState('');
    const [files, setFiles] = useState([]);
    const [numLists, setNumLists] = useState(0);
    const [lists, setLists] = useState([]);
    const [direct, setDirect] = useState(false);
  
    useEffect(() => {
      async function fetchData() {
        try {
          const apiUrl = `${process.env.REACT_APP_API_URL}/posts`;
          const response = await axios.get(apiUrl);

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
  
      fetchData();
    }, []);
  
    async function createNewBlog(ev) {
      ev.preventDefault();
  
      const data = new FormData();
      data.append('name', name);
      data.append('description', description);
      data.append('heading2', heading2);
      data.append('content1', content1);
      data.append('content2', content2);
      data.append('box1', box1);
      data.append('box2', box2);
      data.append('box3', box3);
      data.append('box4', box4);
      data.append('numLists', numLists);
      lists.forEach((list, index) => {
        data.append(`list${index}`, list);
      });
      files.forEach((file) => {
        data.append('files', file);
      });
  
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/products`, data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        if (response.status === 200) {
          setDirect(true);
          setName('');
          setDescription('');
          setHeading2('');
          setContent1('');
          setContent2('');
          setBox1('');
          setBox2('');
          setBox3('');
          setBox4('');
          setFiles([]);
          setNumLists(0);
          setLists([]);
        }
  
      } catch (error) {
        console.error('Error creating post:', error);
      }
    }
  
    const handleFileChange = (event) => {
      setFiles(Array.from(event.target.files));
    };
  
    const handleListChange = (index, value) => {
      const newLists = [...lists];
      newLists[index] = value;
      setLists(newLists);
    };
  
    const handleNumListsChange = (ev) => {
      const value = parseInt(ev.target.value);
      if (isNaN(value) || value < 0) {
        setNumLists(0);
        setLists([]);
      } else {
        setNumLists(value);
        setLists(new Array(value).fill(''));
      }
    };
  
  return (
    <div className='container'>
    <h2 className='create_blog_head'>Create Products</h2>
    <form onSubmit={createNewBlog}>
      <div className='field_div'>
        <label className='label_text'>Product Heading</label>
        <input
          className='inputform'
          type='text'
          placeholder='Enter Product Heading'
          value={name}
          onChange={ev => setName(ev.target.value)}
        />
      </div>
      <div className='field_div'>
        <label className='label_text'>Description</label>
        <textarea
          className='inputdescr'
          rows='3'
          placeholder='Enter Description'
          value={description}
          onChange={ev => setDescription(ev.target.value)}
        />
      </div>
      <div className='field_div'>
        <label className='label_text'>Product Box1</label>
        <input
          className='inputform'
          type='text'
          placeholder='Enter Product Box1'
          value={box1}
          onChange={ev => setBox1(ev.target.value)}
        />
      </div>
      <div className='field_div'>
        <label className='label_text'>Product Box2</label>
        <input
          className='inputform'
          type='text'
          placeholder='Enter Product Box2'
          value={box2}
          onChange={ev => setBox2(ev.target.value)}
        />
      </div>
      <div className='field_div'>
        <label className='label_text'>Product Box3</label>
        <input
          className='inputform'
          type='text'
          placeholder='Enter Product Box3'
          value={box3}
          onChange={ev => setBox3(ev.target.value)}
        />
      </div>
      <div className='field_div'>
        <label className='label_text'>Product Box4</label>
        <input
          className='inputform'
          type='text'
          placeholder='Enter Product Box4'
          value={box4}
          onChange={ev => setBox4(ev.target.value)}
        />
      </div>
      <div className='field_div'>
        <label className='label_text'>Product Heading2</label>
        <input
          className='inputform'
          type='text'
          placeholder='Enter Product Heading2'
          value={heading2}
          onChange={ev => setHeading2(ev.target.value)}
        />
      </div>
      <div className='field_div'>
        <label className='label_text'>Image</label><br />
        <div className='inputform d-flex align-items-center justify-content-end'>
          <input type='file' onChange={handleFileChange} multiple />
        </div>
      </div>
      <div className='field_div'>
        <label className='label_text'>Number of Lists</label>
        <input
          className='inputform'
          type='number'
          placeholder='Enter Number of Lists'
          value={numLists}
          onChange={handleNumListsChange}
        />
      </div>
      {lists.map((list, index) => (
        <div key={index} className='field_div'>
          <label className='label_text'>List {index + 1}</label>
          <input
            className='inputform'
            type='text'
            placeholder={`Enter List ${index + 1}`}
            value={list}
            onChange={ev => handleListChange(index, ev.target.value)}
          />
        </div>
      ))}
      <div className='field_div'>
        <label className='label_text'>Content1</label>
        <ReactQuill value={content1} onChange={newValue => setContent1(newValue)} />
      </div>
      <div className='field_div'>
        <label className='label_text'>Content2</label>
        <ReactQuill value={content2} onChange={newValue => setContent2(newValue)} />
      </div>
      <div className='field_div'>
        <button className='create_btn'>Create Product</button>
      </div>
    </form>
  </div>
  )
}

export default Panel2