import React from 'react'
import HeroSection from './HomeSection/HeroSection'
import HomeSection1 from './HomeSection/HomeSection1'
import HomeAbout from './HomeSection/HomeAbout'
import HomeSection3 from './HomeSection/HomeSection3'
import HomeSolution from './HomeSection/HomeSolution'
import HomeSection4 from './HomeSection/HomeSection4'
import HomeSection5 from './HomeSection/HomeSection5'
import HomeSectors from './HomeSection/HomeSectors'
import HomeProjects from './HomeSection/HomeProjects'
import HomeBlog from './HomeSection/HomeBlog'
import HomeClient from './HomeSection/HomeClient'
import HomeClient2 from './HomeSection/HomeClient2'
import HomeTestimonial from './HomeSection/HomeTestimonial'

const Home = () => {
  return (
    <div>
       <HeroSection/>
       <HomeSection1/>
       <HomeAbout/>
       <HomeSection3/>
       <HomeSolution/>
       <HomeSection4/>
       <HomeSection5/>
       <HomeSectors/>
       <HomeProjects/>
       <HomeBlog/>
       <HomeClient/>
       <HomeClient2/>
       <HomeTestimonial/>
    </div>
  )
}

export default Home