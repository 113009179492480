import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn, faXTwitter } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <div>
    <div className='footback'>
        <div className='container'>
        <div className='row '>
        <div className='col-md-4 mt-5'>
        <img src='/logo.webp' className='footerlogo'  alt='hh'/>
        <p className='footertext'>When you choose Wapp, you choose a global provider of fast-to-deploy, decentralized and packaged water, wastewater, treatment/reuse solutions.</p>
        </div>
        <div className='col-md-2 mt-5'>
            <h3 className='foothead'>Quick link</h3>
                <li className='footertext'><Link to={"/Solutions"} className='no-underline footertext1'>Solution</Link></li>
                <li className='footertext'><Link to={"/product&services"} className='no-underline footertext1'>Product</Link></li>
                <li className='footertext'><Link to={"/Sectors"} className='no-underline footertext1'>Sectors</Link></li>
                <li className='footertext'><Link to={"/about"} className='no-underline footertext1'>About</Link></li>
                <li className='footertext'><Link to={"/blog"} className='no-underline footertext1'>Blogs</Link></li>
        </div>
        <div className='col-md-3 mt-5'>
            <h3 className='foothead'>Reach Us</h3>
               
                <div className="d-flex footertext1"><div className='icon'><FontAwesomeIcon icon={faBriefcase} /></div><li ><Link to={"/career"} className='no-underline footertext1'>Career</Link></li></div>
                <div className="d-flex footertext1"><div className="icon"><FontAwesomeIcon icon={faEnvelope} /></div><li ><Link to={"mailto:marketing@wappsys.com"} className='no-underline footertext1'>marketing@wappsys.com</Link></li></div>
                <div className="d-flex footertext1"><div className="icon"><FontAwesomeIcon icon={faPhone}   /></div><li ><Link to={"tel:+97178 40888"} className='no-underline footertext1'>97178 40888</Link></li></div>
                <div className="d-flex footertext1"><div className="icon"><FontAwesomeIcon icon={faLocationDot} className='locationicon'   /></div><li className='footeraddress'><a href='https://maps.app.goo.gl/ebn56a4gQaMNsA5U9' target="_blank" rel="noopener noreferrer">Enhanced WAPP Systems (I) Pvt Ltd. 914/B, 9th Floor Park Centra, NH 8 Sector 30,Gurgaon, Haryana  </a></li></div>
        </div>
        <div className='col-md-2 mt-5 '>
            <h3 className='foothead'>Follow Us</h3>
            <div className='d-flex'>
              <a href='https://www.linkedin.com/company/wappsys-india-water-managment/' target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedinIn} className="icon1 footertext1"/></a>
            <a href='https://x.com/wappsys1996/status/1771123178300907908?s=46' target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faXTwitter} className="icon1 footertext1"/></a>
            </div>
        </div>
        </div>
        </div>
    
    </div>
    <div className='footback2 d-flex justify-content-center'>
    <p className='foottext2'>Copyright © 2024 Wappsys. All Rights Reserved.</p>
    </div>
    </div>
  )
}

export default Footer