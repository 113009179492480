import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import '../AdminPanel.css'


const Panel1 = () => {
    const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [content1, setContent1] = useState('');
  const [content2, setContent2] = useState('');
  const [content3, setContent3] = useState('');
  const [content4, setContent4] = useState('');
  const [content5, setContent5] = useState('');
  const [files, setFiles] = useState([]);
  const [category, setCategory] = useState('');
  const [direct, setDirect] = useState(false);

  async function createNewBlog(ev) {
    ev.preventDefault();

    const data = new FormData();
    data.append('name', name);
    data.append('description', description);
    data.append('content1', content1);
    data.append('content2', content2);
    data.append('content3', content3);
    data.append('content4', content4);
    data.append('content5', content5);
    data.append('category', category);

    files.forEach((file) => {
      data.append('files', file);
    });

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/posts`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.status === 201) {
        setDirect(true);
        setName('');
        setDescription('');
        setContent1('');
        setContent2('');
        setContent3('');
        setContent4('');
        setContent5('');
        setFiles([]);
        setCategory('');
      }
      console.log('Post created:', response.data);
    } catch (error) {
      console.error('Error creating post:', error);
    }
  }
  
  const handleFileChange = (event) => {
    setFiles(Array.from(event.target.files));
  };

  return (
    <div className='container'>
    <h2 className='create_blog_head'>Create Blog</h2>
    <form onSubmit={createNewBlog}>
      <div className='field_div'>
        <label className='label_text'>Blog Heading</label>
        <input
          className='inputform'
          type='text'
          placeholder='Enter Blog Heading'
          value={name}
          onChange={ev => setName(ev.target.value)}
        />
      </div>
      <div className='field_div'>
        <label className='label_text'>Description</label>
        <textarea
          className='inputdescr'
          rows='5'
          type='text'
          placeholder='Enter Description'
          value={description}
          onChange={ev => setDescription(ev.target.value)}
        />
      </div>
      <div className='field_div'>
        <label className='label_text'>Image</label><br/>
        <div className='inputform d-flex align-items-center justify-content-end'>
          <input type='file' onChange={handleFileChange} multiple />
        </div>
      </div>
      <div className='field_div'>
        <label className='label_text'>Content1</label>
        <ReactQuill value={content1} onChange={newValue => setContent1(newValue)} />
      </div>
      <div className='field_div'>
        <label className='label_text'>Content2</label>
        <ReactQuill value={content2} onChange={newValue => setContent2(newValue)} />
      </div>
      <div className='field_div'>
        <label className='label_text'>Content3</label>
        <ReactQuill value={content3} onChange={newValue => setContent3(newValue)} />
      </div>
      <div className='field_div'>
        <label className='label_text'>Content4</label>
        <ReactQuill value={content4} onChange={newValue => setContent4(newValue)} />
      </div>
      <div className='field_div'>
        <label className='label_text'>Content5</label>
        <ReactQuill value={content5} onChange={newValue => setContent5(newValue)} />
      </div>
      <div className='field_div'>
        <button className='create_btn'>Create Blog</button>
      </div>
    </form>
  </div>
  )
}

export default Panel1