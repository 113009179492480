import React from 'react'
import '../About.css'

const AboutSection1 = () => {
  return (
    <div className='containers2'>
    <h1 className='abouthead1 mt-5'>Total Water Resource Management : <span className='colouredhead'>Navigating Sustainable Solutions</span></h1>
    <p className='abouttext'>Established in 1996, Wapp is a pioneer in water and wastewater treatment with nearly three decades of experience. Our core focus is on Total Water Management, blending cutting-edge technology with customized solutions & services.</p>
    <p className='abouttext'>With a nationwide presence and a team of 400+ people, we empower our clients with eco-friendly solutions. Our portfolio boasts over 1000+ successful projects and 200+ operations and maintenance contracts, making us a trusted partner in harnessing advanced technology for responsible water management. Join us in shaping a sustainable future through efficient, customer-centric solutions.</p>
    <h3 className='abouthead1 mt-3'>Save Water - <span className='colouredhead'>Save Energy </span>- Save Environment</h3>
    <p className='abouttext'>WAPP is working towards building a modern, healthier and sustainable world. We use our expertise, experience, and passion to provide innovative, integrated solutions in the field of water, waste, energy, and environment across urban, industrial and rural markets. With solutions, that are environmentally viable and sustainable, we balance progress with technology that helps restore and regenerate our earth.</p>
</div>
  )
}

export default AboutSection1