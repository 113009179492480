import React from 'react'
import '../Blog.css'

const BlogHero = () => {
  return (
    <div className='blogback'>
        <h1 className="blogherohead">Blog</h1>
    </div>
  )
}

export default BlogHero