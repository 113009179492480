import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const HomeProjects = () => {
    const Project = ({ imgSrc, title, description }) => (
        <div className="d-flex align-items-center">
          <div className='projectsimg2div'>
            <img src={imgSrc} className="projectsimg2" alt="projectimage" />
          </div>
          <div>
            <h6>{title}</h6>
            <p className="homesectorstext">{description}</p>
          </div>
        </div>
      );
      const projects = [
        { imgSrc: "/Vedanta.webp", title: "Vedanta Limited (ESL)", description: "Jharkhand - (50, 75,100,150,200, KLD - MBBR)" },
        { imgSrc: "/larsen and toubro.webp", title: "Larson & Tubro (L&T)", description: "Hyderabad Airport Expansion Project - 2.6 MLD" },
        { imgSrc: "/dlf.webp", title: "DLF Cyber City", description: "Gurgaon- 7 MLD - SAFF STP" },
        { imgSrc: "/Britannia.webp", title: "Britannia", description: "Britannia- 12 MLD - CETP" }
      ];
  return (
    <div className="projectsback">
    <div className="containers1">
      <div className="row d-flex mt-4">
        <div className="col-lg-3 col-md-5 col-sm-4">
          <h2 className="homeabouthead">Key <span className="homeabouthead1"> Projects</span></h2>
        </div>
      </div>
      <div className="row flexii mt-3">
        <div className="col-md-6 col-lg-5 abt_wrap">
          <hr className='mt-4' />
          {projects.map((project, index) => (
            <React.Fragment key={index}>
              <Project {...project} />
              {index !== projects.length - 1 && <hr />}
            </React.Fragment>
          ))}
          <hr className='mt-3' />
          <a href='/product&services' style={{ textDecoration: 'none' }}>
            <div className="d-flex align-items-center mt-3">
              <span className="getinbutt2">See All</span>
              <FontAwesomeIcon icon={faArrowRight} className="arrow1 ml-2" />
            </div>
          </a>
        </div>
        <div className="col-md-6 col-lg-5 offset-lg-2 abt_wrap mb-md-0 mb-xs-5">
          <img src="/Homeprojectimg2.webp" className="projectimg1" alt="projectimg" loading="lazy" />
          <img src="/Homeprojectimg1.webp" className="projectimg" alt="projectimg" loading="lazy" />
        </div>
      </div>
    </div>
  </div>
  )
}

export default HomeProjects