import React from 'react'
import '../Zero.css'

const contentItems = [
    {
     item: "01",
      head: "Environmental Compliance",
      text: "Stringent environmental regulations require organizations to minimize their wastewater discharge. ZLD helps companies stay compliant and avoid heavy penalties."
    },
    {
      item: "02",
      head: "Water Conservation",
      text: "ZLD systems recover up to 95-99% of wastewater, significantly reducing water consumption and helping organizations meet sustainability goals."
    },
    {
      item: "03",
      head: "Resource Recovery",
      text: "ZLD allows for the recovery of valuable by-products from the wastewater, turning waste into a resource and adding an additional revenue stream."
    },
    {
      item: "04",
      head: "Reduced Environmental Impact",
      text: "By eliminating wastewater discharge, ZLD systems prevent pollution of water bodies and groundwater, protecting local ecosystems."
    },
    {
      item: "05",
      head: "Operational Sustainability",
      text: "Implementing ZLD helps in maintaining a sustainable operation by reducing dependency on freshwater resources and mitigating the risks associated with water scarcity."
    },
    {
      item: "06",
      head: "Source diversification",
      text: "Source diversification involves expanding water sources to ensure a more resilient and sustainable supply. This approach helps communities and industries promote long-term water security."
    }
  ];

const ZeroSection2 = () => {
  return (
    <>

    <div className='container adv_service_desk'>
      
        <div className='row mt-4'>
        <div className='  col-2'>
        {contentItems.map((content, index) => (
           <div key={index} className='list mb-4'>
            <img className='list_img' src="/sollist.webp" alt='sol'/>
            <div className='overlay_item'>
              <p className='listtext'>{content.item}</p>
           </div>
           </div>
          ))}
           </div>
           <div className=' col-10'>
          {contentItems.map((content, index) => (
           <div key={index}>
            <div className='list1 mb-4'>
              <h1 className='listhead'>{content.head}</h1>
              <p className='listtext1'>{content.text}</p>
           </div>
           </div>
          ))}
        </div>
        </div>
    </div>


    <div className='adv_service_mob'>

{contentItems.map((content, index) => (
            <div key={index}>
              <div className='adv_mob_head '>
              <p className='listtext'>{content.item}</p>
              <h1 className='listhead'>{content.head}</h1>
              </div>
              <p className='listtext1'>{content.text}</p>
            </div>
          ))}
</div>


 
    </>
  )
}

export default ZeroSection2