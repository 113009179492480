import { useState, useEffect } from 'react';
import '../../Urban/Urban.css'

const InfraSection1 = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({ title: '', title1:'', title2:'', title3: '', content: '', head1: '', list1: '', head2: '', head3: '', head4: '', head5: '', head6: '', head7:'', head8: '', list2: '', list3: '', list4: '', list5: '', list6: '', list7: '', list8: '', list9: '', list10: '', list11: '', list12: '', list13: '', list14: '', list15: '', logo1: '', logo2: '', logo3: '',logo4: '',logo5: ''  });
  
    const openModal = (item) => {
        setSelectedItem(item);
        setShowModal(true);
    };
  
    const closeModal = () => {
        setShowModal(false);
        setSelectedItem({ title: '', title1:'', title2: '', title3: '', content: '', head1: '',  head2: '', head3: '', head4: '', head5: '', head6: '', head7:'', head8: '', list1: '', list2: '', list3: '', list4: '', list5: '', list6: '', list7: '', list8: '', list9: '', list10: '', list11: '', list12: '', list13: '', list14: '', list15: '', logo1: '', logo2: '', logo3: '',logo4: '',logo5: '' });
    };
    
    useEffect(() => {
      if (showModal) {
          document.body.classList.add('no-scroll');
      } else {
          document.body.classList.remove('no-scroll');
      }
  
      return () => {
          document.body.classList.remove('no-scroll');
      };
  }, [showModal]);
  
  
    const details = {
      hotels: {
          title: 'Smart City and Large Infrastructure',
          content: 'Effective wastewater management in these infrastructure projects not only enhances environmental sustainability but also supports public health and operational efficiency by safeguarding clean water resources and minimizing environmental impact across diverse urban and transportation settings.',
              img1: '/heavy-engineering.webp',
              title1: 'Services Offered',
              list1: 'Supply, Installation, Testing, and Commissioning (SITC) of a wide range of Sewage Treatment Plant (STP).',
              list2: 'Comprehensive & non-comprehensive operation and maintenance of STP/WTP',
              title2: 'Challenges in wastewater management for infrastructure projects such as airports, metro systems, railways, and large city developments include:',
              head2: 'Complexity of Sources: ',
              list6: ' Managing diverse wastewater sources from terminals, tracks, stations, and urban facilities, each with unique contaminants and treatment needs.',
              head3: 'Advanced Treatment Requirements: ',
              list7: 'Meeting stringent regulatory standards with advanced treatment technologies like membrane bioreactors (MBRs) or UV disinfection systems can be costly and technically demanding.',
              head4: 'Infrastructure Constraints:',
              list8: 'Designing and maintaining robust infrastructure for efficient wastewater collection, treatment, and disposal, especially in densely populated urban areas or remote locations.',
              head5:'Operational Challenges:  ',
              list9:'Addressing fluctuating wastewater volumes, ensuring continuous monitoring, and managing maintenance to sustain reliable wastewater management systems.',
              head6: 'Water Reuse Implementation: ',
              list10: 'Overcoming logistical and technical hurdles to effectively implement water reuse systems for recycling treated wastewater, which can be complex and require specialized expertise.',
              logo1: '/ltblue.webp',
              logo2: '/surface.webp',
              logo3: '/gmr.webp',
              logo4: "/varindera.webp",
              logo5: '/Adani.webp'
      },
  };
  return (
    <div>
    <div className='infraback commonback '>
        <h1 class="sechead">Infrastructure</h1>
    </div>
    <div className='containers1'>
    <div className='abt_wrap mt-5'>
                    <img src='/urbanimg4.webp' className='urbanimg1' alt='im' />
                    <p className='urbantext'>Smart City and Large Infrastructure</p>
                    <p className='urbantext1' onClick={() => openModal(details.hotels)}>View Full Detail</p>
                </div>
    </div>
    {showModal && (
       <div className='modal'>
                    <div className='modal-sectorcontent'>
                        <span className='close' onClick={closeModal}>x</span>
                        <div className='container'>
                            <div className='row'>
                        <h2 className='urban_title'>{selectedItem.title}</h2>
                        <div className='col-lg-5 col-md-12 '>
                            <img className='hotels_img' src={selectedItem.img1} alt='taj'/>
                    </div>
                  <div className='col-lg-7 col-md-12'>
                  <p className='hotels_content'>{selectedItem.content}</p>
                  <h5 className='hotels_head'>{selectedItem.title1}</h5>
                  {selectedItem.list1 && <li className='item_text'>{selectedItem.list1}</li>}
                        {selectedItem.list2 && <li className='item_text'>{selectedItem.list2}</li>}
                        {selectedItem.list3 && <li className='item_text'>{selectedItem.list3}</li>}
                        {selectedItem.list4 && <li className='item_text'>{selectedItem.list4}</li>}
                        {selectedItem.list5 && <li className='item_text'>{selectedItem.list5}</li>}
                    </div>
<div className='col-sm-12 mt-4'>
    <h5  className='hotels_head'>{selectedItem.title2}</h5>
  
    {selectedItem.head2 && <li className='item_text'>{selectedItem.head2}<span>{selectedItem.list6}</span></li>}
    {selectedItem.head3 &&  <li className='item_text'>{selectedItem.head3}<span>{selectedItem.list7}</span></li>}
    {selectedItem.head4 && <li className='item_text'>{selectedItem.head4}<span>{selectedItem.list8}</span></li>}
    {selectedItem.head5 && <li className='item_text'>{selectedItem.head5}<span>{selectedItem.list9}</span></li>}
    {selectedItem.head6 && <li className='item_text'>{selectedItem.head6}<span>{selectedItem.list10}</span></li>}
    {selectedItem.head7 &&<li className='item_text'>{selectedItem.head7}<span>{selectedItem.list11}</span></li>}
    {selectedItem.head8 &&<li className='item_text'>{selectedItem.head8}<span>{selectedItem.list12}</span></li>}
    </div>
    <div className='col-12 mt-4'>
<h5 className='hotels_head'>{selectedItem.title3}</h5>
{selectedItem.list13 && <li className='item_text'>{selectedItem.list13}</li>}
{selectedItem.list14 && <li className='item_text'>{selectedItem.list14}</li>}
{selectedItem.list15 && <li className='item_text'>{selectedItem.list15}</li>}
    </div>
    <div className='col-12 mt-4'>
    <h4>Clients</h4>
    <div className='client-container'>
    {selectedItem.logo1 &&  <img className='hotels_logo' src={selectedItem.logo1} alt='lg'/>}
    {selectedItem.logo2 &&  <img className='hotels_logo' src={selectedItem.logo2} alt='lg'/>}
    {selectedItem.logo3 &&  <img className='hotels_logo' src={selectedItem.logo3} alt='lg'/>}
    {selectedItem.logo4 &&  <img className='hotels_logo' src={selectedItem.logo4} alt='lg'/>}
    {selectedItem.logo5 &&  <img className='hotels_logo' src={selectedItem.logo5} alt='lg'/>}
    </div>
        </div>
                    </div>
                    </div>
                    </div>
                </div>
      )}
    </div>
  )
}

export default InfraSection1