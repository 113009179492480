import React from 'react'
import '../Product.css'

const ProductSection1 = () => {
  return (
    <>
    <div className='productback'>
        <h1 className="productherohead"> Product & Services</h1>
    </div>
    <div className="containers2 mt-5 mb-5 ">
    <h3 className='abouthead1 mt-3'>Our   <span className='colouredhead'>Technology</span></h3>
    <p className='abouttext'>WAPP is working towards building a modern, healthier and sustainable world. We use our expertise, experience, and passion to provide innovative, integrated solutions in the field of water, waste, energy, and environment across urban, industrial and rural markets. With solutions, that are environmentally viable and sustainable, we balance progress with technology that helps restore and regenerate our earth.</p>
    </div>
    </>
  )
}

export default ProductSection1