import React from 'react'
import CareerHero from './CareerSection/CareerHero'
import CareerSection1 from './CareerSection/CareerSection1'
import ContactForm from '../Contact/ContactSection/ContactForm'

const Career = () => {
  return (
    <div>
        <CareerHero/>
        <CareerSection1/>
        <ContactForm/>
    </div>
  )
}

export default Career