import { memo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Autoplay, FreeMode } from 'swiper/modules';

const ClientEntry = memo(({ imgSrc }) => (
  <div className="d-flex justify-content-between mb-2">
    <img src={imgSrc} className="clientimg" alt="clientimage" loading="lazy" />
  </div>
));

// Adding display name to the memoized component
ClientEntry.displayName = 'ClientEntry';

const HomeClient2 = () => {
  const client = [
    { imgSrc: "/lalit.webp" },
    { imgSrc: "/larsen and toubro.webp" },
    { imgSrc: "/m3m.webp" },
    { imgSrc: "/NTPC.webp" },
    { imgSrc: "/oberoi.webp" },
    { imgSrc: "/select-citywalk.webp" },
    { imgSrc: "/surface.webp" },
    { imgSrc: "/Vedanta.webp" },
    { imgSrc: "/wipro.webp" },
    { imgSrc: "/ITCLimited.webp" },
  ];
  return (
    <div className='containers1'>
    <Swiper
      modules={[Navigation, Autoplay, FreeMode]}
      slidesPerView={3}
      spaceBetween={20}
      loop={true}
      freeMode={true}
      autoplay={{
        delay: 10,
        disableOnInteraction: false,
        reverseDirection: true,
      }}
      speed={1000}
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }}
      pagination={{ clickable: true }}
      breakpoints={{
        1024: {
          slidesPerView: 7,
        },
        768: {
          slidesPerView: 4,
        },
      }}
    >
      {client.map((entry, index) => (
        <SwiperSlide key={index}>
          <ClientEntry imgSrc={entry.imgSrc} />
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
  )
}

export default HomeClient2