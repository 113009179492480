import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faBars } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';

const Navbar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("/");
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDocumentClick = useCallback((event) => {
    if (!event.target.closest(".menu") && !event.target.closest(".navbars")) {
      closeModal();
    }
  }, []);

  const handleLinkClick = (path) => {
    setActiveLink(path);
    closeModal();
  };

  useEffect(() => {
    const pathname = window.location.pathname;
    if (
      pathname.startsWith("/Solutions") ||
      pathname === "/TotalWaterManagement" ||
      pathname === "/Treatmentplant" ||
      pathname === "/SupplyInstallation" || 
      pathname === "/Zeroliquid"
    ) {
      setActiveLink("/Solutions");
    } else if (
      pathname.startsWith("/Sectors") ||
      pathname === "/Urban" ||
      pathname === "/Industry" ||
      pathname === "/Infra"
    ) {
      setActiveLink("/Sectors");
    } else {
      setActiveLink(pathname);
    }
  }, []);
  
  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [handleDocumentClick]);

  const handleSearch = async (event) => {
   
    if (event.key === 'Enter') {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/posts`); 
        const posts = response.data;

        const matchedBlog = posts.find(post => post.description.includes(searchTerm)|| post.content1.includes(searchTerm) || post.content2.includes(searchTerm) || post.content3.includes(searchTerm) || post.content4.includes(searchTerm) || post.content5.includes(searchTerm));
        if (matchedBlog) {
          navigate(`/blogpage/${matchedBlog._id}?search=${encodeURIComponent(searchTerm)}`);
        } else {
          alert('No matching blog found');
        }
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    }
  };
  

  return (
    <div className="Navdiv">
      <div className="container-fluid">
        <div className="navcontainer ">
          <div className="">
            <Link href="/">
            <img  src="/logo.webp" className="navlogo" alt="logo" />
            </Link>
          </div>
          <div className="">
            <Link to="/" className="no-underline">
              <p 
                className={`navtext  ${activeLink === "/" ? "active" : ""}`} 
                onClick={() => handleLinkClick("/")}
              >
                Home
              </p>
            </Link>
          </div>
          <div className="">
            <Link to="/about" className="no-underline">
              <p 
                className={`navtext  ${activeLink === "/about" ? "active" : ""}`} 
                onClick={() => handleLinkClick("/about")}
              >
                About
              </p>
            </Link>
          </div>
          <div className="">
            <Link to="/Solutions" className="no-underline">
              <p 
                className={`navtext  ${activeLink === "/Solutions" ? "active" : ""}`} 
                onClick={() => handleLinkClick("/Solutions")}
              >
                Solutions
              </p>
            </Link>
          </div>
          <div className="">
            <Link to="/product&services" className="no-underline">
              <p 
                className={`navtext  ${activeLink === "/product&services" ? "active" : ""}`} 
                onClick={() => handleLinkClick("/product&services")}
              >
                Product & Services
              </p>
            </Link>
          </div>
          <div className="">
            <Link to="/Sectors" className="no-underline">
              <p 
                className={`navtext  ${activeLink === "/Sectors" ? "active" : ""}`} 
                onClick={() => handleLinkClick("/Sectors")}
              >
                Sectors
              </p>
            </Link>
          </div>
          <div className="">
            <Link to="/blog" className="no-underline">
              <p 
                className={`navtext  ${activeLink === "/blog" ? "active" : ""}`} 
                onClick={() => handleLinkClick("/blog")}
              >
                Blog
              </p>
            </Link>
          </div>
          <div className="">
            <Link to="/contact" className="no-underline">
              <p 
                className={`navtext  ${activeLink === "/contact" ? "active" : ""}`} 
                onClick={() => handleLinkClick("/contact")}
              >
                Contact
              </p>
            </Link>
          </div>
          <div className="input-container ">
            <FontAwesomeIcon icon={faSearch} className="navsearch" />
            <input 
              type="text" 
              placeholder="Search" 
              className="navinput" 
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleSearch}
            />
            <FontAwesomeIcon icon={faBars} className="navbars" onClick={toggleModal} />
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="menu">
          <Link className="no-underline active" to="/">
            <p className={`navtext0 ${activeLink === "/" ? "active" : ""}`} onClick={() => handleLinkClick("/")}>
              Home
            </p>
          </Link>
          <Link className="no-underline active" to="/about">
            <p className={`navtext0 ${activeLink === "/about" ? "active" : ""}`} onClick={() => handleLinkClick("/about")}>
              About
            </p>
          </Link>
          <Link className="no-underline active" to="/Solutions">
            <p className={`navtext0 ${activeLink === "/Solutions" ? "active" : ""}`} onClick={() => handleLinkClick("/Solutions")}>
              Solutions
            </p>
          </Link>
          <Link className="no-underline active" to="/product&services">
            <p className={`navtext0 ${activeLink === "/product&services" ? "active" : ""}`} onClick={() => handleLinkClick("/product&services")}>
              Product & Services
            </p>
          </Link>
          <Link className="no-underline active" to="/Sectors">
            <p className={`navtext0 ${activeLink === "/Sectors" ? "active" : ""}`} onClick={() => handleLinkClick("/Sectors")}>
              Sectors
            </p>
          </Link>
          <Link className="no-underline active" to="/blog">
            <p className={`navtext0 ${activeLink === "/blog" ? "active" : ""}`} onClick={() => handleLinkClick("/blog")}>
              Blog
            </p>
          </Link>
          <Link className="no-underline active" to="/contact">
            <p className={`navtext0 ${activeLink === "/contact" ? "active" : ""}`} onClick={() => handleLinkClick("/contact")}>
              Contact
            </p>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Navbar;