import React from 'react'
import { useState, useEffect } from 'react';
import '../Urban.css';

const UrbanSection1 = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({ title: '', title1:'', title2:'', title3: '', content: '', head1: '', list1: '', head2: '', head3: '', head4: '', head5: '', head6: '', head7:'', head8: '', list2: '', list3: '', list4: '', list5: '', list6: '', list7: '', list8: '', list9: '', list10: '', list11: '', list12: '', list13: '', list14: '', list15: '', logo1: '', logo2: '', logo3: '', logo4: '', logo5: '', logo6: '' });

    const openModal = (item) => {
        setSelectedItem(item);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedItem({ title: '', title1:'', title2: '', title3: '', content: '', head1: '',  head2: '', head3: '', head4: '', head5: '', head6: '', head7:'', head8: '', list1: '', list2: '', list3: '', list4: '', list5: '', list6: '', list7: '', list8: '', list9: '', list10: '', list11: '', list12: '', list13: '', list14: '', list15: '', logo1: '', logo2: '', logo3: '', logo4: '', logo5: '', logo6: '' });
    };

    useEffect(() => {
        if (showModal) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [showModal]);

    const details = {
        hotels: {
            title: 'Hotels & Resorts',
            content: 'Effective wastewater management in hotels and resorts is essential for environmental sustainability and regulatory compliance. These establishments employ on-site treatment systems to purify wastewater before discharge, ensuring it meets local standards. Water conservation measures, such as low-flow fixtures and reuse programs, minimize wastewater production. Regular monitoring ensures compliance with environmental laws, while staff training promotes proper disposal practices. Collaboration with stakeholders and adoption of innovative technologies further enhance efficiency and sustainability, benefiting both the environment and the establishment reputation.',
            img1: '/taj.webp',
            title1: 'Services Offered in Hotels',
            list1: 'Supply, Installation, Testing, and Commissioning (SITC) of a wide range of Sewage Treatment Plant (STP) systems',
            list2: 'Effluent treatment plant - laundry recycling',
            list3: 'A separate greywater treatment plant',
            list4: 'Total Water management',
            list5: 'Comprehensive & non-comprehensive operation and maintenance of STP/ETP/WTP',
            title2: 'Challenges in managing wastewater in the hospitality sector:',
            head2: 'Volume and Variability: ',
            list6: ' Hotels generate large, varying amounts of wastewater daily from diverse sources like kitchens, laundry, and guest amenities.',
            head3: 'Water Quality Standards: ',
            list7: 'Ensuring wastewater meets stringent quality standards for discharge, including removal of pollutants like chemicals and pathogens.',
            head4: 'Space Limitations: ',
            list8: ' Limited space for installing and maintaining effective wastewater treatment systems, especially in urban or densely populated areas.',
            head5:'Cost of Treatment: ',
            list9:'High costs associated with implementing and maintaining advanced treatment technologies, which can be prohibitive for smaller establishments.',
            head6: 'Regulatory Compliance:',
            list10: 'Navigating complex environmental regulations and obtaining permits for wastewater discharge, requiring ongoing monitoring and reporting.',
            head7: 'Seasonal Fluctuations: ',
            list11: 'Handling seasonal variations in wastewater volume due to fluctuations in occupancy and operational activities.',
            head8: 'Public Perception: ',
            list12: 'Potential negative impact on the hotels reputation if wastewater management practices are perceived as inadequate or environmentally harmful.',
            title3: 'Consultancy',
            list13: 'Plant Adequacy',
            list14: 'Water audit',
            list15: 'Water design report for efficiency (TWM - design)',
            logo1: '/oberoi.webp',
            logo2: '/Taj_Falakhnuma.webp',
            logo3: '/Hyatt.webp',
            logo4: '/ITCLimited.webp',
            logo5: '/radisson.webp',
            logo6: '/JW.webp'
        },
        commercial: {
            title: 'Commercial & IT Park',
            content: 'Wastewater management in commercial and IT parks focuses on implementing advanced treatment systems like MBRs and RO to meet strict quality standards. Water reuse systems are employed to reduce freshwater demand, while smart technologies monitor usage and detect leaks. Compliance with regulations and regular monitoring ensure environmental safety, supported by education initiatives promoting water conservation. Collaboration with stakeholders fosters innovation, ensuring sustainable practices and minimizing environmental impact in these dynamic sectors.',
            img1: '/it-park.webp',
            title1: 'Services Offered in Commercial and IT Park',
            list1: 'Supply, Installation, Testing, and Commissioning (SITC) of a wide range of Sewage Treatment Plant (STP) & Effluent treatment plant (ETP) SystemsTo maintain weekdays and weekly loads.',
            list2: 'Total Water management',
            list3: 'Comprehensive & non-comprehensive operation and maintenance of STP/ETP/WTP',
            title2: 'Challenges in managing wastewater in commercial and IT parks include:',
            head2: 'Diverse Sources: ',
            list6: 'Wastewater comes from varied sources like offices, cafeterias, and cooling systems, each requiring specific treatment.',
            head3: 'Volume Fluctuations:',
            list7: 'Variable occupancy and operational changes lead to fluctuating wastewater volumes, necessitating flexible treatment solutions.',
            head4: 'Stringent Standards: ',
            list8: 'Meeting strict water quality regulations demands advanced treatment technologies and ongoing monitoring.',
            head5: 'Cost and Space Constraints: ',
            list9: ' Installing and maintaining effective treatment systems can be expensive and challenging in urban settings with limited space.',
            head6: 'Technology Integration: ',
            list10: 'Integrating smart water management technologies requires expertise and compatibility with existing infrastructure.',
            head7: 'Reputation Management: ',
            list11: 'Maintaining a positive public image and addressing environmental concerns associated with wastewater management practices.',
            logo4: '/m3m.webp',
            logo2: '/Brookfield.webp',
            logo3: '/lodha.webp',
            logo1: '/dlf.webp',
            logo5: '/Reliance.webp',
            logo6: '/Cushman.webp'
        },
        hospitals: {
            title: 'Hospitals',
            content: 'Wastewater management in hospitals is essential for safeguarding public health and environmental integrity. It involves treating diverse contaminants from medical procedures and sanitation facilities to meet stringent regulatory standards. Hospitals employ advanced technologies such as disinfection and filtration systems to ensure wastewater is safe for discharge. However, managing hospital wastewater is resource-intensive due to high volumes and specialized infrastructure requirements. Continuous monitoring and compliance with regulations are crucial to maintain effective operations and protect community health from potential hazards associated with medical waste and contaminants.',
            img1: '/fortis_img.webp',
            title1: 'Services Offered in Hospitals',
            list1: 'Supply, Installation, Testing, and Commissioning (SITC) of a wide range of Sewage Treatment Plant (STP) & Effluent treatment plant (ETP) Systems.',
            list2: 'Treatment of greywater and blackwater',
            list3: 'Total Water management',
            list4: 'Comprehensive & non-comprehensive operation and maintenance of STP/ETP/WTP',
            title2: 'Challenges in managing hospital wastewater include:',
            head2: 'Complex Contaminants: ',
            list6: 'Treating diverse pollutants from medical activities and pharmaceuticals requires specialized processes.',
            head3: 'Stringent Regulations:',
            list7: 'Hospitals must meet strict environmental and health standards for wastewater discharge, necessitating advanced treatment methods and continuous monitoring.',
            head4: 'Volume and Variability:  ',
            list8: 'Fluctuating wastewater volumes due to patient numbers and medical procedures require flexible treatment solutions.',
            head5: 'Cost and Infrastructure:  ',
            list9: 'Installing and maintaining effective treatment systems can be expensive, especially for facilities with older infrastructure.',
            head6: 'Public Health Risks: ',
            list10: 'Proper management is crucial to prevent infections and ensure wastewater does not pose health risks to the community.',
            head7: 'Training and Compliance: ',
            list11: 'Staff education and adherence to protocols are vital to handle hazardous waste properly and meet regulatory requirements effectively.',
            logo1: '/medanta.webp',
            logo2: '/fortis-hospitals.webp',
            logo3: '/Escorts.webp',
            logo4: '/manipal.webp',
            logo5: '/jaypee.webp',
            logo6: '/Ganga.webp'          
        }
    };

  return (
    <div className=' mt-lg-0 mt-sm-5'>
    <div className='urbancontainer'>
        <div className='abt_wrap '>
            <img src='/urbanimg1.webp' className='urbanimg' alt='ur' />
            <p className='urbantext'>Hotels & Resorts</p>
            <p className='urbantext1' onClick={() => openModal(details.hotels)}>View Full Detail</p>
        </div>
        <div className='abt_wrap'>
            <img src='/urbanimg2.webp' className='urbanimg' alt='ur' />
            <p className='urbantext'>Commercial & IT Park</p>
            <p className='urbantext1' onClick={() => openModal(details.commercial)}>View Full Detail</p>
        </div>
        <div className='abt_wrap'>
            <img src='/urbanimg3.webp' className='urbanimg' alt='ur' />
            <p className='urbantext'>Hospitals</p>
            <p className='urbantext1' onClick={() => openModal(details.hospitals)}>View Full Detail</p>
        </div>
    </div>

    {showModal && (
        <div className='modal'>
            <div className='modal-sectorcontent'>
                <span className='closex' onClick={closeModal}>x</span>
                <div className='container'>
                    <div className='row'>
                <h2 className='urban_title'>{selectedItem.title}</h2>
                <div className='col-lg-5 col-md-12 '>
                    <img className='hotels_img' src={selectedItem.img1} alt='taj'/>
            </div>
          <div className='col-lg-7 col-md-12'>
          <p className='hotels_content'>{selectedItem.content}</p>
          <h5 className='hotels_head'>{selectedItem.title1}</h5>
          {selectedItem.list1 && <li className='item_text'>{selectedItem.list1}</li>}
                {selectedItem.list2 && <li className='item_text'>{selectedItem.list2}</li>}
                {selectedItem.list3 && <li className='item_text'>{selectedItem.list3}</li>}
                {selectedItem.list4 && <li className='item_text'>{selectedItem.list4}</li>}
                {selectedItem.list5 && <li className='item_text'>{selectedItem.list5}</li>}
            </div>
<div className='col-sm-12 mt-4'>
<h5  className='hotels_head'>{selectedItem.title2}</h5>

{selectedItem.head2 && <li className='item_text'>{selectedItem.head2}<span>{selectedItem.list6}</span></li>}
{selectedItem.head3 &&  <li className='item_text'>{selectedItem.head3}<span>{selectedItem.list7}</span></li>}
{selectedItem.head4 && <li className='item_text'>{selectedItem.head4}<span>{selectedItem.list8}</span></li>}
{selectedItem.head5 && <li className='item_text'>{selectedItem.head5}<span>{selectedItem.list9}</span></li>}
{selectedItem.head6 && <li className='item_text'>{selectedItem.head6}<span>{selectedItem.list10}</span></li>}
{selectedItem.head7 &&<li className='item_text'>{selectedItem.head7}<span>{selectedItem.list11}</span></li>}
{selectedItem.head8 &&<li className='item_text'>{selectedItem.head8}<span>{selectedItem.list12}</span></li>}
</div>
<div className='col-12 mt-4'>
<h5 className='hotels_head'>{selectedItem.title3}</h5>
{selectedItem.list13 && <li className='item_text'>{selectedItem.list13}</li>}
{selectedItem.list14 && <li className='item_text'>{selectedItem.list14}</li>}
{selectedItem.list15 && <li className='item_text'>{selectedItem.list15}</li>}
</div>
<div className='col-12 mt-4'>
<h4>Clients</h4>
<div className='client-container'>
{selectedItem.logo1 &&  <img className='hotels_logo' src={selectedItem.logo1} alt='lg'/>}
{selectedItem.logo2 &&  <img className='hotels_logo' src={selectedItem.logo2} alt='lg'/>}
{selectedItem.logo3 &&  <img className='hotels_logo' src={selectedItem.logo3} alt='lg'/>}
{selectedItem.logo4 &&  <img className='hotels_logo' src={selectedItem.logo4} alt='lg'/>}
{selectedItem.logo5 &&  <img className='hotels_logo' src={selectedItem.logo5} alt='lg'/>}
{selectedItem.logo6 &&  <img className='hotels_logo' src={selectedItem.logo6} alt='lg'/>}
</div>
</div>
            </div>
            </div>
            </div>
        </div>
    )}
</div>
  )
}

export default UrbanSection1