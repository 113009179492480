import { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import '../About.css';

const IconLink = memo(({ href, icon }) => (
    <a href={href}>
      <FontAwesomeIcon icon={icon} className='icon1' />
    </a>
  ));
  IconLink.displayName = 'IconLink';

const AboutLeadership = () => {
  return (
    <section className='containers1 mt-md-5'>
    <h2 className='abouthead1'>Leader<span className='colouredhead'>ship</span></h2>
    <div className='row mt-3 mb-3'>
      <div className='col-md-4'>
        <img src='/leadershipimg.webp' className='leaderimg' alt='Leader' priority />
      </div>
      <div className='col-md-8'>
        <div className='leaderborder'>
          <p className='abouttext1'>
            In driving economic development and bolstering GDP growth, WAPP remains steadfast in the water and wastewater industry for sustainable progress. We pioneer solutions that address modern challenges while promoting resilience. Leveraging expertise and experience, we deliver innovative, integrated solutions for urban, infrastructural, and industrial needs. With a focus on environmental sustainability, we strike a balance between progress and preservation, ensuring a brighter future for all.
          </p>
          <p className='abouttext1'>
            At WAPP, our success is driven by the exceptional leadership and expertise of our team. Comprising seasoned professionals and experts in the field, we are dedicated to shaping a more sustainable future through innovative solutions. With years of collective experience and a passion for environmental stewardship, our team is committed to delivering excellence in every project we undertake. Whether it&apos;s pioneering new technologies or providing strategic guidance, we stand as industry leaders, ready to tackle the challenges of today and tomorrow. Get to know the faces behind our success and discover how our team can drive positive change for your organization.
          </p>
          <div className='d-flex mb-3'>
            <IconLink href='https://www.linkedin.com/company/wappsys-india-water-managment/' icon={faLinkedinIn} />
            <IconLink href='mailto:marketing@wappsys.com' icon={faEnvelope} />
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

AboutLeadership.displayName = 'AboutLeadership';
export default memo(AboutLeadership);