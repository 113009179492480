import React, { useState, useEffect } from 'react';

const HomeSection1 = () => {
    const [customerCount, setCustomerCount] = useState(0);
    const [employeeCount, setEmployeeCount] = useState(0);
    const [projectsCount, setProjectsCount] = useState(0);
    const [livesTransformedCount, setLivesTransformedCount] = useState(0);
    const [waterSavedCount, setWaterSavedCount] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
          if (customerCount < 1000) {
            setCustomerCount(customerCount + 1);
          }
          if (employeeCount < 1000) {
            setEmployeeCount(employeeCount + 1);
          }
          if (projectsCount < 28) {
            setProjectsCount(projectsCount + 1);
          }
          if (livesTransformedCount < 100) {
            setLivesTransformedCount(livesTransformedCount + 1);
          }
          if (waterSavedCount < 400) {
            setWaterSavedCount(waterSavedCount + 1);
          }
        }, 3);
        return () => clearInterval(interval);
      }, [customerCount, employeeCount, projectsCount, livesTransformedCount, waterSavedCount]);

  return (
     <div className='databack'>
      <div className='container-fluid '>
        <div className='row '>
          <div className='col-lg col-md-4 col-sm-12 d-flex  justify-content-md-center align-items-md-center'>
            <img  src='/user.webp' className='datalogo' alt='icon' />
            <p className='datatext'>{customerCount}+ <br /> Happy Customers</p>
          </div>
          <div className='col-lg-2 col-md-4 col-sm-12  d-flex  justify-content-md-center align-items-md-center'>
            <img  src='/Group.webp' className='datalogo' alt='icon' />
            <p className='datatext'>{employeeCount}+ <br /> Project Completed</p>
          </div>
          <div className='col-lg-3 col-md-4 col-sm-12  d-flex  justify-content-md-center align-items-md-center'>
            <img  src='/projectlogo.webp' className='datalogo ' alt='icon' />
            <p className='datatext'>{projectsCount}+ <br /> Years of Experience </p>
          </div>
          <div className='col-lg col-md-6 col-sm-12 d-flex  justify-content-md-center align-items-md-center'>
            <img  src='/bulblogo.webp' className='datalogo datalogo1' alt='icon' />
            <p className='datatext'>{livesTransformedCount} billion <br />Gallons of Water Saved</p>
          </div>
          <div className='col-lg-2 col-md-6 col-sm-12  d-flex  justify-content-md-center align-items-md-center'>
            <img  src='/dropletlogo.webp' className='datalogo datalogo1' alt='icon' />
            <p className='datatext'>{waterSavedCount}+ <br /> Employees</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeSection1