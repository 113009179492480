import React, { useState } from 'react';
import '../../Solutions/Solution.css'

const TotalSection4 = () => {
    const [expandedSection, setExpandedSection] = useState(null);

    const handleToggle = (section) => {
      setExpandedSection(expandedSection === section ? null : section);
    };
  return (
    <div>
    <h2 className="homeabouthead mt-3 d-flex justify-content-center mb-3">
      Why choose <span className="homeabouthead1 ml-2"> Wapp?</span>
    </h2>
    <div className="containers1">
      <div className="row flexii">
        <div className="col-md-7 col-sm-12 ">
          <div className="d-flex">
            <div className={`greendiv ${expandedSection === 'section1' ? 'expanded' : 'collapsed'}`}>
              <div className="d-flex align-items-center mt-3">
                <h4 className="greentext">Technological Innovation</h4>
                <img
                  className="arrow_down ms-lg-3"
                  src='/arrow_down.webp'
                  alt="Arrow Down"
                  onClick={() => handleToggle('section1')}
                  style={{ cursor: 'pointer' }}
                />
              </div>
              <div className={expandedSection === 'section1' ? 'visible' : 'hidden'}>
                <p className='greentext1'>
                We continuously integrate cutting-edge technologies into our STP solutions to enhance efficiency and effectiveness, staying at the forefront of wastewater treatment advancements.
                </p>
              </div>
            </div>
            <div className={`greendiv2 ${expandedSection === 'section1' ? 'expanded2' : 'collapsed'}`}></div>
          </div>
          <div className="d-flex ">
            <div className={`bluediv ${expandedSection === 'section2' ? 'expanded' : 'collapsed'}`}>
              <div className="d-flex align-items-center mt-3">
                <h4 className="greentext">Comprehensive Support</h4>
                <img
                  className="arrow_down ms-lg-3"
                  src='/arrow_down.webp'
                  alt="Arrow Down"
                  onClick={() => handleToggle('section2')}
                  style={{ cursor: 'pointer' }}
                />
              </div>
              <div className={expandedSection === 'section2' ? 'visible' : 'hidden'}>
                <p className='greentext1'>
                  From initial consultation to post-installation support and maintenance, we offer comprehensive services to ensure the seamless operation and performance of our STP systems.
                </p>
              </div>
            </div>
            <div className={`bluediv2 ${expandedSection === 'section2' ? 'expanded3' : 'collapsed'}`}></div>
          </div>
          <div className="d-flex ">
            <div className={`greendiv ${expandedSection === 'section3' ? 'expanded' : 'collapsed'}`}>
              <div className="d-flex align-items-center mt-3">
                <h4 className="greentext">Commitment to Sustainability</h4>
                <img
                  className="arrow_down ms-lg-3"
                  src='/arrow_down.webp'
                  alt="Arrow Down"
                  onClick={() => handleToggle('section3')}
                  style={{ cursor: 'pointer' }}
                />
              </div>
              <div className={expandedSection === 'section3' ? 'visible' : 'hidden'}>
                <p className='greentext1'>
                  We are dedicated to promoting environmental sustainability through our innovative STP systems, which prioritize resource conservation, energy efficiency, and minimal environmental impact.
                </p>
              </div>
            </div>
            <div className={`greendiv2 ${expandedSection === 'section3' ? 'expanded2' : 'collapsed'}`}></div>
          </div>
          <div className="d-flex">
            <div className={`bluediv ${expandedSection === 'section4' ? 'expanded' : 'collapsed'}`}>
              <div className="d-flex align-items-center mt-3">
                <h4 className="greentext">Proven Track Record</h4>
                <img
                  className="arrow_down ms-lg-3"
                  src='/arrow_down.webp'
                  alt="Arrow Down"
                  onClick={() => handleToggle('section4')}
                  style={{ cursor: 'pointer' }}
                />
              </div>
              <div className={expandedSection === 'section4' ? 'visible' : 'hidden'}>
                <p className='greentext1'>
                With a solid track record of successful projects across various industries and sectors, we have established ourselves as a trusted partner for wastewater treatment solutions.
                </p>
              </div>
            </div>
            <div className={`bluediv2 ${expandedSection === 'section4' ? 'expanded3' : 'collapsed'}`}></div>
          </div>
        </div>
        <div className="col-md-5 col-sm-12">
          <img src="/Totallastimg.webp" className="lastimg" alt="Wapp Services" />
        </div>
      </div>
    </div>
    <div className='containers1'>
      <h2 className="homeabouthead mt-3 d-flex justify-content-center mb-3">Our<span className="homeabouthead1 ml-3">Expertise</span></h2>
      <div className='row'>
        <div className='col-md-4 col-sm-12 '>
          <div className='totallastdiv'>
            <h2 className='lasttotalhead'>Tailored Design and Implementation</h2>
            <p className='lasttotaltext'>Crafting bespoke ETP solutions aligned with diverse industry needs.</p>
          </div>
        </div>
        <div className='col-md-4 col-sm-12'>
          <div className='totallastdiv'>
            <h2 className='lasttotalhead'>Proactive Maintenance and Support</h2>
            <p className='lasttotaltext'>Ensuring ongoing reliability and efficiency through comprehensive service offerings.</p>
          </div>
        </div>
        <div className='col-md-4 col-sm-12'>
          <div className='totallastdiv'>
            <h2 className='lasttotalhead'>Global Leadership in Sustainable Solutions</h2>
            <p className='lasttotaltext'>Implementing cutting-edge technologies for environmentally responsible ETP operations worldwide.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default TotalSection4