import React from 'react'
import '../Sector.css'

const Sector2 = () => {
  return (
    <div className='containers2'>
    <h3 className='abouthead1 mt-5'>Sectors  <span className='colouredhead'>We Serve</span></h3>
    <p className='abouttext'>In driving economic development and bolstering GDP growth, WAPP remains steadfast in the water and wastewater industry for sustainable progress. We pioneer solutions that address modern challenges while promoting resilience. Leveraging expertise and experience, we deliver innovative, integrated solutions for urban, infrastructural, and industrial needs. With a focus on environmental sustainability, we strike a balance between progress and preservation, ensuring a brighter future for all.</p>
  </div>
  )
}

export default Sector2