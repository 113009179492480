import React, { useState, useEffect} from 'react';
import '../Urban.css';

const UrbanSection2 = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({ title: '', title1:'', title2:'', title3: '', content: '', head1: '', list1: '', head2: '', head3: '', head4: '', head5: '', head6: '', head7:'', head8: '', list2: '', list3: '', list4: '', list5: '', list6: '', list7: '', list8: '', list9: '', list10: '', list11: '', list12: '', list13: '', list14: '', list15: '',  logo1: '', logo2: '', logo3: '', logo4: '', logo5: '', logo6: '' });

    const openModal = (item) => {
        setSelectedItem(item);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedItem({ title: '', title1:'', title2: '', title3: '', content: '', head1: '',  head2: '', head3: '', head4: '', head5: '', head6: '', head7:'', head8: '', list1: '', list2: '', list3: '', list4: '', list5: '', list6: '', list7: '', list8: '', list9: '', list10: '', list11: '', list12: '', list13: '', list14: '', list15: '', logo1: '', logo2: '', logo3: '', logo4: '', logo5: '', logo6: '' });
    };
    useEffect(() => {
        if (showModal) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [showModal]);


    const details = {
        hotels: {
            title: 'Reatils & Malls',
            content: 'Wastewater management in the retail and malls sector involves handling diverse sources of wastewater from restrooms, food courts, and cleaning activities. It requires compliance with stringent regulations for wastewater quality and discharge standards through advanced treatment technologies. Water conservation measures like low-flow fixtures and reuse systems are implemented to reduce freshwater usage. Efficient management enhances operational sustainability, reduces costs, and supports environmental stewardship in these high-traffic commercial environments.',
            img1: '/citywalk.webp',
            title1: 'Services Offered in Retails & Malls',
            list1: 'Supply, Installation, Testing, and Commissioning (SITC) of a wide range of Sewage Treatment Plant (STP) & Effluent treatment plant (ETP) Systems',
            list2: 'Total Water management',
            list3: 'Comprehensive & non-comprehensive operation and maintenance of STP/ETP/WTP',
            title2: 'Challenges in managing wastewater in the retail and malls sector include:',
            head2: 'Diverse Sources:',
            list6: 'Wastewater originates from multiple sources such as restrooms, food courts, and cleaning activities, each with different contaminants and treatment requirements.',
            head3: 'Volume and Seasonal Variations :',
            list7: 'Fluctuating wastewater volumes due to varying customer traffic and seasonal demands can strain treatment systems designed for consistent loads.',
            head4: 'Regulatory Compliance:  ',
            list8: 'Meeting strict local regulations for wastewater quality and discharge standards demands continuous monitoring and adherence to environmental guidelines.',
            head5:'Infrastructure Constraints: ',
            list9:'Limited space and existing infrastructure can pose challenges in implementing and maintaining effective wastewater treatment systems.',
            head6: 'Cost of Treatment: ',
            list10: 'Installing and operating advanced treatment technologies can be costly, particularly for smaller retail establishments or malls with budget constraints.',
            head7: 'Public Perception: ',
            list11: 'Maintaining a positive public image requires effective wastewater management to avoid environmental impacts and community concerns. Addressing these challenges requires tailored solutions that balance technological innovation, regulatory compliance, cost-effectiveness, and environmental responsibility to ensure sustainable wastewater management in retail and mall environments.',
            logo1: '/dlf.webp',
            logo4: '/mani.webp',
            logo2: '/select-citywalk.webp',
            logo3: '/Wallmart.webp',
            logo5: '/south.webp',
            logo6: '/trilium.webp'
            },
            

        commercial: {
            title: 'Housing & Township',
           content: 'Wastewater management in the housing and township sector encompasses handling residential wastewater from various sources, including homes, apartments, and community amenities like parks and pools. It involves employing treatment technologies such as sewage treatment plants (STPs) to purify wastewater for safe discharge or reuse, while also focusing on infrastructure planning to ensure efficient collection and disposal in compliance with local regulations. Implementing water reuse systems for activities like irrigation helps conserve freshwater, while community engagement initiatives educate residents on water conservation and proper waste disposal practices. Effective wastewater management promotes environmental sustainability, enhances public health, and improves overall quality of life by safeguarding clean water resources and minimizing environmental impact.',
            img1: '/paragon.webp',
            title1: 'Services Offered in Housing/Township',
            list1: 'Supply, Installation, Testing, and Commissioning (SITC) of a wide range of Sewage Treatment Plant (STP)',
            list2: 'Total Water management.',
            list3: 'Comprehensive & non-comprehensive operation and maintenance of STP/WTP.',
            title2: 'Challenges in wastewater management in the housing and township sector include:',
            head2: 'Infrastructure Limitations: ',
            list6: 'Outdated or inadequate infrastructure requiring costly upgrades to handle wastewater volumes.',
            head3: 'Treatment Costs: ',
            list7: 'High expenses associated with installing and maintaining advanced treatment technologies like sewage treatment plants (STPs).',
            head4: 'Regulatory Compliance: ',
            list8: ': Meeting strict local regulations for wastewater quality and discharge standards necessitates ongoing monitoring and adherence.',
            head5: 'Community Education:  ',
            list9: ' Educating residents on water conservation and proper waste disposal practices is essential but can be challenging to implement effectively.',
            head6: 'Seasonal Fluctuations: ',
            list10: 'Variations in wastewater volumes due to seasonal changes or occupancy rates can strain treatment systems designed for consistent loads.',
            head7: 'Water Reuse Challenges: ',
            list11: 'Overcoming logistical and technical hurdles in implementing systems for reusing treated wastewater for non-potable purposes.',
            logo1: '/dlf.webp',
            logo2: '/Gaurs.webp',
            logo3: '/m3m.webp',
            logo4: '/Godrej.webp',
            logo5: '/varindera.webp',
            logo6: '/IREO.webp'
        },
    };

  return (
    <div>
        <div className='urbancontainer1'>
                <div className='abt_wrap'>
                    <img src='/urbanimg4.webp' className='urbanimg' alt='ur' />
                    <p className='urbantext'>Retails & Malls</p>
                    <p className='urbantext1' onClick={() => openModal(details.hotels)}>View Full Detail</p>
                </div>
                <div className='abt_wrap'>
                    <img src='/urbanimg5.webp' className='urbanimg' alt='ur' />
                    <p className='urbantext'>Housing & Township</p>
                    <p className='urbantext1' onClick={() => openModal(details.commercial)}>View Full Detail</p>
                </div>
            </div>
        {showModal && (
                  <div className='modal'>
                   <div className='modal-sectorcontent'>
                        <span className='close' onClick={closeModal}>x</span>
                        <div className='container'>
                            <div className='row'>
                        <h2 className='urban_title'>{selectedItem.title}</h2>
                        <div className='col-lg-5 col-md-12 '>
                            <img className='hotels_img' src={selectedItem.img1} alt='taj'/>
                    </div>
                  <div className='col-lg-7 col-md-12'>
                  <p className='hotels_content'>{selectedItem.content}</p>
                  <h5 className='hotels_head'>{selectedItem.title1}</h5>
                  {selectedItem.list1 && <li className='item_text'>{selectedItem.list1}</li>}
                        {selectedItem.list2 && <li className='item_text'>{selectedItem.list2}</li>}
                        {selectedItem.list3 && <li className='item_text'>{selectedItem.list3}</li>}
                        {selectedItem.list4 && <li className='item_text'>{selectedItem.list4}</li>}
                        {selectedItem.list5 && <li className='item_text'>{selectedItem.list5}</li>}
                    </div>
<div className='col-sm-12 mt-4'>
    <h5  className='hotels_head'>{selectedItem.title2}</h5>
  
    {selectedItem.head2 && <li className='item_text'>{selectedItem.head2}<span>{selectedItem.list6}</span></li>}
    {selectedItem.head3 &&  <li className='item_text'>{selectedItem.head3}<span>{selectedItem.list7}</span></li>}
    {selectedItem.head4 && <li className='item_text'>{selectedItem.head4}<span>{selectedItem.list8}</span></li>}
    {selectedItem.head5 && <li className='item_text'>{selectedItem.head5}<span>{selectedItem.list9}</span></li>}
    {selectedItem.head6 && <li className='item_text'>{selectedItem.head6}<span>{selectedItem.list10}</span></li>}
    {selectedItem.head7 &&<li className='item_text'>{selectedItem.head7}<span>{selectedItem.list11}</span></li>}
    {selectedItem.head8 &&<li className='item_text'>{selectedItem.head8}<span>{selectedItem.list12}</span></li>}
    </div>
    <div className='col-12 mt-4'>
<h5 className='hotels_head'>{selectedItem.title3}</h5>
{selectedItem.list13 && <li className='item_text'>{selectedItem.list13}</li>}
{selectedItem.list14 && <li className='item_text'>{selectedItem.list14}</li>}
{selectedItem.list15 && <li className='item_text'>{selectedItem.list15}</li>}
    </div>
    <div className='col-12 mt-4'>
    <h4>Clients</h4>
    <div className='client-container'>
    {selectedItem.logo1 &&  <img className='hotels_logo' src={selectedItem.logo1}  alt='lg'/>}
    {selectedItem.logo2 &&  <img className='hotels_logo' src={selectedItem.logo2} alt='lg'/>}
    {selectedItem.logo3 &&  <img className='hotels_logo' src={selectedItem.logo3}  alt='lg'/>}
    {selectedItem.logo4 &&  <img className='hotels_logo' src={selectedItem.logo4}  alt='lg'/>}
    {selectedItem.logo5 &&  <img className='hotels_logo' src={selectedItem.logo5} alt='lg'/>}
    {selectedItem.logo6 &&  <img className='hotels_logo' src={selectedItem.logo6} alt='lg'/>}
    </div>
        </div>
                    </div>
                    </div>
                    </div>
              </div>
            )}
    </div>
  )
}

export default UrbanSection2