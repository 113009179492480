import React from 'react'
import '../Solution.css'

const SolutionHero = () => {
  return (
    <div className="solutionheroback">
    <h1 className='solutionherohead'>Solutions</h1>
    <h4 className='solutionherosubhead'>Reinventing Water Management</h4>
    </div>
  )
}

export default SolutionHero