import React from 'react'
import '../About.css'

const AboutHero = () => {
  return (
    <div className='aboutback'>
    <h1 className='abouthead'>Sustainable Water : Our Commitment</h1>
    <h3 className='aboutsubhead'>From initial concepts to final execution, Wapp remains dedicated to exceeding your expectations, offering the convenience of a one-stop solution for all your water management needs</h3>
</div>
  )
}

export default AboutHero