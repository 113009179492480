import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
const Sector = ({ imgSrc, title, description }) => (
  <>
    <hr className='mt-4'/>
    <div className="d-flex  align-items-center ">
      <div className='sector_img_div'>
        <img  src={imgSrc} className="sectorsimg2" alt="sectorsimages"/>
      </div>
      <div>
        <h6>{title}</h6>
        <p className="homesectorstext">{description}</p>
      </div>
    </div>
  </>
);

const HomeSectors = () => {
    const sectors = [
        { imgSrc: "/urbanimg1.webp", title: "Hotel & Resorts", description: "Hotel companies have both a strong commercial and moral imperative for addressing water use." },
        { imgSrc: "/urbanimg4.webp", title: "Retails & Malls", description: "This sector has both a strong commercial and moral imperative for addressing water use. " },
        { imgSrc: "/urbanimg3.webp", title: "Hospitals", description: "Over the past 10 years, the costs of water and wastewater services have risen at a rate. " },
        { imgSrc: "/urbanimg5.webp", title: "Housing & Township", description: "Water is a precious and a dwindling resource all over the world, even in a housing society." }
      ];
      useEffect(() => {
        const observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              const lazyImage = entry.target;
              lazyImage.src = lazyImage.dataset.src;
              observer.unobserve(lazyImage);
            }
          });
        });
        document.querySelectorAll('.lazy').forEach(img => observer.observe(img));
        return () => {
          observer.disconnect();
        };
      }, []);
  return (
    <div className="sectorsbackd">
      <div className="containers1 mt-2">
        <div className="row d-flex">               
          <div className="col-lg-3 col-md-5 col-sm-4 ">
            <h2 className="homeabouthead">Our <span className="homeabouthead1"> Sectors</span></h2>
          </div>
        </div>
        <div className="row mt-3 prohomecardinner">
          <div className="col-md-7 col-lg-6 mb-md-0 mb-xs-5 prohomecardinner">
            <img  src="/Homesectorsimg2.webp" className="sectorsimg" alt="sectorsimages"/>
            <img  src="/Homesectorsimg1.webp" className="sectorsimg1" alt="sectorsimages"/>
          </div>
          <div className="col-md-5 offset-lg-1 col-lg-5 " >
            {sectors.map((sector, index) => (
              <Sector key={index} {...sector} />
            ))}
            <hr className='mt-4' />
            <a href='/Sectors' className='no-underline'>
              <div className="d-flex align-items-center mt-3 mb-3">
                <span className="getinbutt2">See All</span>
                <FontAwesomeIcon icon={faArrowRight} className="arrow1 ms-2" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeSectors