import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const HomeAbout = () => {
  return (
    <div className="containers1 mt-3">
    <div className="row">
      <div className="col-md-6">
        <div className="d-flex">
          <div className="col-xs-4 col-sm-4 col-lg-4 col-md-5">
            <h2 className="homeabouthead">About <span className="homeabouthead1">Us</span></h2>
          </div>
        </div>
        <div className="ellipse">
          <h4 className="homeaboutsubhead">Total Water Resource Management: Navigating Sustainable Solutions.</h4>
          <p className="homeabouttext">Established in 1996, Wapp is a pioneer in water and wastewater treatment with nearly three decades of experience. Our core focus is on Total Water Management, blending cutting-edge technology with customised solutions & services. With a nationwide presence and a team of 400+ people, we empower our clients with eco-friendly solutions. Our portfolio boasts over 1000+ successful projects and 200+ operations and maintenance contracts, making us a trusted partner in harnessing advanced technology for responsible water management.</p>
          <p className="homeabouttext">Join us in shaping a sustainable future through efficient, customer-centric solutions</p>
          <div className="d-flex align-items-center justify-content-center mt-3 see_more_btn">
            <a href='/about' className="see_more_link">
              <span className="see_text">See More</span>
              <FontAwesomeIcon icon={faArrowRight} className="arrowicon ms-2" />
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="d-flex abt_wrap">
          <div className="homeabout_image_wrapper">
            <div className="homeabout_image_container">
              <img src="/homeaboutimg1.webp" className="homeabout1"  alt="img" />
            </div>
            <div className="homeabout_image_container">
              <img src="/homeaboutimg2.webp" className="homeabout2" alt="img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default HomeAbout