import React from 'react'
import { Autoplay} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

const testimonials = [
    {
      name: "Pardeep Sood",
      position: "Vice President of Indo",
      feedback1: "We highly value Wapp for their superior technical expertise and proven track record in handling high-value projects. They suggested great solutions tailored to our needs, were honest, and delivered on time.",
      imgSrc: "/client1.webp",
      treeIconSrc: "/tree.webp",
      starIconSrc: "/Star.webp",
    },
    {
      name: "Amit Jain",
      position: "Brookefields",
      feedback1: "Wapp has demonstrated excellent project ownership and proactive senior management, meeting our high standards for product quality and response time. Their exemplary design coordination and support in finalizing items are greatly appreciated.",
      imgSrc: "/client2.webp",
      treeIconSrc: "/tree.webp",
      starIconSrc: "/Star.webp",
    },
    {
      name: "Jahanvi Kulshreshtha",
      position: "VP-Engineering",
      feedback1: "Wappsys is a professional team ideal for water and wastewater sustainability programs. They have consistently helped us deliver complex engineering solutions on time and within budget.",
      imgSrc: "/client3.webp",
      treeIconSrc: "/tree.webp",
      starIconSrc: "/Star.webp",
    },
    {
      name: "Ashish Bhughani",
      position: "Digital Eco Innovision Pvt Ltd",
      feedback1: "We are thrilled to partner with Enhanced Wapp Systems, whose expertise ensures exceptional operational efficiency and seamless project execution in water treatment. We look forward to achieving even greater milestones together.",
      imgSrc: "/digitalpaani.webp",
      treeIconSrc: "/tree.webp",
      starIconSrc: "/Star.webp",
    },
  ];

const Testimonials = () => {
  return (
    <>
    <div className='containers1 testimonial_desk_div'>           
<h2 className="abouthead1">Clients <span className="homeabouthead1"> Testimonials</span></h2>
<div className='row d-flex align-items-center justify-content-between mt-3'>
        {testimonials.map((testimonial, index) => (
          <div key={index} className='col-lg-3 col-md-6 col-sm-12 mb-3'>
            <div className="clientcard">
              <div className="clientcard1">
                <div className="testimonial_div">
                  <img src={testimonial.imgSrc} className="testdp" alt="testimonial" />
                  <h4 className="testhead">{testimonial.name}</h4>
                  <h5 className="testsubhead d-flex justify-content-center">
                    <img src={testimonial.treeIconSrc} className="treeicon" alt="tree icon" /> {testimonial.position}
                  </h5>
                </div>
                <div className="testimonial_text_div">
                  <p className="testtext d-flex justify-content-center">{testimonial.feedback1}</p>
                </div>
                <div className="testimonial_star_div">
                  {[...Array(4)].map((_, starIndex) => (
                    <img key={starIndex} src={testimonial.starIconSrc} className="staricon" alt="star icon" />
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    <div className="containers1 testimonial_mob_div mt-5">
      <h2 className="homeabouthead">
        Clients <span className="homeabouthead1">Testimonials</span>
      </h2>
      <div className='row d-flex align-items-center justify-content-between mt-3'>
        <Swiper
          modules={[Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          loop={true}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          lazy="true"
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                <div className="clientcard">
                  <div className="clientcard1">
                    <div className="testimonial_div">
                      <img src={testimonial.imgSrc} className="testdp" alt="testimonial" />
                      <h4 className="testhead">{testimonial.name}</h4>
                      <h5 className="testsubhead d-flex justify-content-center">
                        <img src={testimonial.treeIconSrc} className="treeicon" alt="tree icon" /> {testimonial.position}
                      </h5>
                    </div>
                    <div className="testimonial_text_div">
                      <p className="testtext d-flex justify-content-center">{testimonial.feedback1}</p>
                    </div>
                    <div className="testimonial_star_div">
                      {[...Array(4)].map((_, starIndex) => (
                        <img key={starIndex} src={testimonial.starIconSrc} className="staricon" alt="star icon" />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
    </>
  )
}

export default Testimonials