import React from 'react'
import '../Sector.css'
import '../../Solutions/Solution.css'

const Sector1 = () => {
  return (
    <div className='sectorsback'>
    <h1 className='solutionherohead'>Sectors</h1>
<h4 className='sectorsherosubhead'>Reinventing the ways water has been managed</h4>
</div>
  )
}

export default Sector1