import React from 'react'
import SolutionHero from './SolutionSection/SolutionHero'
import SolutionSection1 from './SolutionSection/SolutionSection1'
import SolutionSection2 from './SolutionSection/SolutionSection2'
import OurClient from '../About/AboutSection/OurClient'
import Testimonials from '../About/AboutSection/Testimonials'

const Solutions = () => {
  return (
    <>
   <SolutionHero/>
   <SolutionSection1/>
   <SolutionSection2/>
   <OurClient/>
   <Testimonials/>
   </>
  )
}

export default Solutions