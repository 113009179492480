import React from 'react'
import '../../Solutions/Solution.css'

const contentItems = [
    {
     item: "01",
      head: "Water circularity",
      text: "Water circularity in total management, via advanced wastewater treatment, turns wastewater into a valuable resource, reducing environmental impact and promoting recycling. Freshwater demand equals total system losses."
    },
    {
      item: "02",
      head: "Minimizing losses ",
      text: "TWM involves minimizing losses by treating the reject water (from RO, Cooling towers, softener reject water) and optimizing freshwater demand by implementing sustainable technologies. This approach ensures long-term environmental sustainability."
    },
    {
      item: "03",
      head: "Zero liquid discharge with sustainable technology",
      text: "Zero liquid discharge (ZLD) is a sustainable water management approach that minimizes water wastage by treating wastewater. Advanced technologies with low operating costs and no evaporation can lead to sustainable ZLD at a lower operating costs."
    },
    {
      item: "04",
      head: "Demand-side management",
      text: "Demand-side management in total water management involves mapping, budgeting, and controlling water consumption to optimize resources and minimize waste. This approach enhances water conservation, resilience, and environmental sustainability.."
    },
    {
      item: "05",
      head: "Rainwater harvesting",
      text: "Rainwater harvesting collects and stores rainwater, reducing reliance on conventional sources and mitigating water stress. Systems like rooftop catchments manage stormwater runoff, reducing flooding and soil erosion risks."
    },
    {
      item: "06",
      head: "Source diversification",
      text: "Source diversification involves expanding water sources to ensure a more resilient and sustainable supply. This approach helps communities and industries promote long-term water security."
    }
  ];

const TotalSection2 = () => {
  return (
     <>

    <div className='container adv_service_desk'>
      
        <div className='row mt-4'>
        <div className='  col-2'>
        {contentItems.map((content, index) => (
           <div key={index} className='list mb-4'>
            <img className='list_img' src="/sollist.webp"  alt='sol'/>
            <div className='overlay_item'>
              <p className='listtext'>{content.item}</p>
           </div>
           </div>
          ))}
           </div>
           <div className=' col-10'>
          {contentItems.map((content, index) => (
           <div key={index}>
            <div className='list1 mb-4'>
              <h1 className='listhead'>{content.head}</h1>
              <p className='listtext1'>{content.text}</p>
           </div>
           </div>
          ))}
        </div>
        </div>
    </div>

    <div className='adv_service_mob'>
{contentItems.map((content, index) => (
            <div key={index}>
              <div className='adv_mob_head '>
              <p className='listtext'>{content.item}</p>
              <h1 className='listhead'>{content.head}</h1>
              </div>
              <p className='listtext1'>{content.text}</p>
            </div>
          ))}
</div>


 
    </>
  )
}

export default TotalSection2