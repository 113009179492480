import React from 'react'
import '../../Urban/Urban.css'

const IndustryHero = () => {
  return (
    <div className='industryback commonback '>
    <h1 class="sechead">Industry</h1>
</div>
  )
}

export default IndustryHero