import React from 'react'
import '../Contact.css'

const ContactHero = () => {
  return (
    <div className='contactback'>
        <h1 className="contactherohead">Contact Us</h1>
    </div>
  )
}

export default ContactHero