import { useState, useEffect } from "react";
import '../../Urban/Urban.css'

const IndustrySection3 = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({title: '', title1:'', title2:'', title3: '', content: '', head1: '', list1: '', head2: '', head3: '', head4: '', head5: '', head6: '', head7:'', head8: '', list2: '', list3: '', list4: '', list5: '', list6: '', list7: '', list8: '', list9: '', list10: '', list11: '', list12: '', list13: '', list14: '', list15: '', logo1: '', logo2: '', logo3: '', logo4: ''  });
  const openModal = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
    setSelectedItem({title: '', title1:'', title2:'', title3: '', content: '', head1: '', list1: '', head2: '', head3: '', head4: '', head5: '', head6: '', head7:'', head8: '', list2: '', list3: '', list4: '', list5: '', list6: '', list7: '', list8: '', list9: '', list10: '', list11: '', list12: '', list13: '', list14: '', list15: '', logo1: '', logo2: '', logo3: '', logo4: ''   });
  };  
  useEffect(() => {
    if (showModal) {
        document.body.classList.add('no-scroll');
    } else {
        document.body.classList.remove('no-scroll');
    }
    return () => {
        document.body.classList.remove('no-scroll');
    };
}, [showModal]);
  const details = {
    Retails: {
      title: 'Steel and metals',
      content: 'This sector has both a strong commercial and moral imperative for addressing water use. Cost is a clear factor; water accounts for 10% of utility bills in many retails and malls.',
      list1: 'To maintain weekdays and weekly loads.',
      list2: 'To maintain water demand according to the seasonal variation.',
      list3: 'To achieve zero liquid discharge.',
      list4: 'Minimum fresh water requirement.',
      list5: 'Minimum apparent losses.',
      list6: 'Demand-side management.',
      list7: 'To provide centralized drinking water to reduce cost.'
    },
    Housing: {
      title: 'Textile',
            content: 'Water is a precious and a dwindling resource all over the world, even in a housing society. Increasing human population is piling a huge demand on the overall water resources, and reservoirs are drying up, and water tables are correspondingly going down. Housing societies everywhere are looking for ways to conserve water usage and replenish water reserves.',
            list1: 'To achieve zero liquid discharge.',
            list2: 'Minimum fresh water requirement.',
            list3: 'Minimum apparent losses.',
            list4: 'Demand side management.',
            list5: 'To provide centralized drinking water to reduce cost.',
            list6: 'To provide separate STP for grey and black water for maximum reuse of water.',
    },
  };
  return (
    <div>
    <div className="urbancontainer2">
    <div className='abt_wrap'>
                <img src='/urbanimg4.webp' className='urbanimg' alt='im' />
                <p className='urbantext'>Textile</p>
                <p className='urbantext1' onClick={() => openModal(details.hotels)}>View Full Detail</p>
            </div>       
    <div className="abt_wrap">
      <img src="/urbanimg1.webp" className="urbanimg" alt="im" />
      <p className="urbantext">CETPS</p>
      <p className="urbantext1" onClick={() => openModal(details.Retails)}>
        View Full Detail
      </p>
    </div>
    <div className="abt_wrap">
      <img src="/urbanimg2.webp" className="urbanimg" alt="im" />
      <p className="urbantext">MUNICIPAL</p>
      <p
        className="urbantext1"
        onClick={() => openModal(details.Housing)}
      >
        View Full Detail
      </p>
    </div>
</div>
  {showModal && (
    <div className="modal">
       <div className='modal-sectorcontent'>
                    <span className='close' onClick={closeModal}>x</span>
                    <div className='container'>
                        <div className='row'>
                    <h2 className='urban_title'>{selectedItem.title}</h2>
                    <div className='col-lg-5 col-md-12 '>
                        <img className='hotels_img' src={selectedItem.img1} alt='taj'/>
                </div>
              <div className='col-lg-7 col-md-12'>
              <p className='hotels_content'>{selectedItem.content}</p>
              <h5 className='hotels_head'>{selectedItem.title1}</h5>
              {selectedItem.list1 && <li className='item_text'>{selectedItem.list1}</li>}
                    {selectedItem.list2 && <li className='item_text'>{selectedItem.list2}</li>}
                    {selectedItem.list3 && <li className='item_text'>{selectedItem.list3}</li>}
                    {selectedItem.list4 && <li className='item_text'>{selectedItem.list4}</li>}
                    {selectedItem.list5 && <li className='item_text'>{selectedItem.list5}</li>}
                </div>
<div className='col-sm-12 mt-4'>
<h5  className='hotels_head'>{selectedItem.title2}</h5>  
{selectedItem.head2 && <li className='item_text'>{selectedItem.head2}<span>{selectedItem.list6}</span></li>}
{selectedItem.head3 &&  <li className='item_text'>{selectedItem.head3}<span>{selectedItem.list7}</span></li>}
{selectedItem.head4 && <li className='item_text'>{selectedItem.head4}<span>{selectedItem.list8}</span></li>}
{selectedItem.head5 && <li className='item_text'>{selectedItem.head5}<span>{selectedItem.list9}</span></li>}
{selectedItem.head6 && <li className='item_text'>{selectedItem.head6}<span>{selectedItem.list10}</span></li>}
{selectedItem.head7 &&<li className='item_text'>{selectedItem.head7}<span>{selectedItem.list11}</span></li>}
{selectedItem.head8 &&<li className='item_text'>{selectedItem.head8}<span>{selectedItem.list12}</span></li>}
</div>
<div className='col-12 mt-4'>
<h5 className='hotels_head'>{selectedItem.title3}</h5>
{selectedItem.list13 && <li className='item_text'>{selectedItem.list13}</li>}
{selectedItem.list14 && <li className='item_text'>{selectedItem.list14}</li>}
{selectedItem.list15 && <li className='item_text'>{selectedItem.list15}</li>}
</div>
<div className='col-12 mt-4'>
<h4>Clients</h4>
<div className='client-container'>
{selectedItem.logo1 &&  <img className='hotels_logo' src={selectedItem.logo1} alt='lg'/>}
{selectedItem.logo2 &&  <img className='hotels_logo' src={selectedItem.logo2} alt='lg'/>}
{selectedItem.logo3 &&  <img className='hotels_logo' src={selectedItem.logo3} alt='lg'/>}
{selectedItem.logo4 &&  <img className='hotels_logo' src={selectedItem.logo4} alt='lg'/>}
</div>
    </div>
                </div>
                </div>
                </div>
    </div>
  )}
</div>
  )
}

export default IndustrySection3