import React from 'react'
import '../Zero.css'

const ZeroSection1 = () => {
  return (
    <>
    <div className='zeroliquidback'>
<h4 className='supply_title'>Zero Liquid Discharge</h4>
    </div>
     <div className='containers1'>
     <p className='supplytext'>At Enhanced WAPP, we specialize in providing comprehensive solutions for the Supply, Installation, Testing, and Commissioning (SITC) of a wide range of Sewage Treatment Plant (STP) systems. Our expertise encompasses various cutting-edge technologies designed to efficiently treat sewage and wastewater, ensuring environmental compliance, and promoting sustainable resource management practices.</p>
     <div className='d-flex justify-content-center'><h2 className="homeabouthead mt-3 ">Why ZLD is <span className="homeabouthead1">  Important for Organizations:</span></h2></div>
   </div>
   </>
  )
}

export default ZeroSection1