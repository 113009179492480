import React from 'react'
import BlogHero from './BlogSection/BlogHero'
import BlogSection1 from './BlogSection/BlogSection1'

const Blog = () => {
  return (
    <div>
        <BlogHero/>
        <BlogSection1/>
    </div>
  )
}

export default Blog