import React from 'react'
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

const approachItems = [
    {
      title: "Guided Consulting (Customer-Centric)",
      text: "At Wapp, we embrace the uniqueness of every project. We understand our customers' needs & vision, crafting tailor-made, sustainable solutions to optimize your water systems' efficiency.",
      iconSrc: "/ourapproachicon.webp",
      className: "ourapproach"
    },
    {
      title: "Expertise and Innovation (Technology-Driven)",
      text: " Our substantial investment in R&D empowers us to pioneer products that elevate safety standards while significantly diminishing our environmental footprint.",
      iconSrc: "/ourapproachicon.webp",
      className: "ourapproach1"
    },
    {
      title: "End-to-End Services (Comprehensive Solutions)",
      text: "Wapp offers full-service water management, from evaluation to design, delivery, and ongoing optimization—a one-stop solution for your needs.",
      iconSrc: "/ourapproachicon.webp",
      className: "ourapproach"
    }
  ];

const HomeSection3 = () => {
  return (
    <>
    <div className="containers1 approach_desk_div">
      <div className="row d-flex">
        <div className="col-lg-3 col-md-5 col-sm-4">
          <h2 className="homeabouthead">Our <span className="homeabouthead1">Approach</span></h2>
        </div>
      </div>
      <div className="row d-flex justify-content-between mt-3">
        {approachItems.map((item, index) => (
          <div key={index} className="col-md-6 col-lg-4">
            <div className={item.className}>
              <div className="d-flex justify-content-center">
                <img 
                  src={item.iconSrc} 
                  className="ouricon" 
                  alt="icon" 
                  loading="lazy"
                />
                <h4 className="ourhead">{item.title}</h4>
              </div>
              <div className={item.className === 'ourapproach' ? 'app_text_div1' : 'app_text_div1'}>
                <p className="section3text">{item.text}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>

    <div className="containers1 approach_mob_div">
      <div className="row d-flex mt-3">
        <div className="col-lg-3 col-md-5 col-sm-4">
          <h2 className="homeabouthead">Our <span className="homeabouthead1">Approach</span></h2>
        </div>
      </div>
      <Swiper
        modules={[Autoplay]}
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
      >
        {approachItems.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="row d-flex justify-content-center">
              <div className="col-md-6 col-lg-4">
                <div className={item.className}>
                  <div className="d-flex justify-content-center">
                    <img  
                      src={item.iconSrc} 
                      className="ouricon" 
                      alt="icon" 
                      loading="lazy"
                    />
                    <h4 className="ourhead">{item.title}</h4>
                  </div>
                  <div className={item.className === 'ourapproach' ? 'app_text_div1' : 'app_text_div1'}>
                    <p className="section3text">{item.text}</p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  </>
  )
}

export default HomeSection3