import React from 'react'
import TreatmentSection1 from './TreatmentSection/TreatmentSection1'
import TreatmantSection2 from './TreatmentSection/TreatmantSection2'
import TreatmentSection3 from './TreatmentSection/TreatmentSection3'
import TotalSection4 from '../TotalWater/TotalSection/TotalSection4'

const Treatment = () => {
  return (
    <div>
        <TreatmentSection1/>
        <TreatmantSection2/>
        <TreatmentSection3/>
        <TotalSection4/>
    </div>
  )
}

export default Treatment