import React from 'react'
import '../Sector.css'

const Sector3 = () => {
  return (
    <div className='containers1'>
      <div className='sectorcontainer mb-5'>
        <a href='./Urban'><div className='sector-item'>
        <h2 className='sec-img-text'>Urban</h2>
          <img src='/sectorsimg1.webp' className='sector-img' alt='gg' />
        </div></a>
        <a href='./Infra'><div className='sector-item'>
        <h2 className='sec-img-text'>Infra</h2>
          <img src='/sectorsimg2.webp' className='sector-img' alt='gg' />
        </div></a>
        <a href='./Industry'><div className='sector-item'>
        <h2 className='sec-img-text'>Industry</h2>
          <img src='/sectorsimg3.webp' className='sector-img' alt='gg' />
        </div></a>
      </div>
    </div>
  )
}

export default Sector3