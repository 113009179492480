import { useEffect } from 'react';
import { BrowserRouter as Router,  Route, Routes } from "react-router-dom";
import Home from './component/Home/Home';
import Navbar from './component/Navbar/Navbar';
import Footer from './component/Footer/Footer';
import About from './component/About/About';
import Solutions from './component/Solutions/Solutions';
import Sector from './component/Sectors/Sector';
import Product from './component/Product&services/Product';
import Blog from './component/Blog/Blog';
import Contact from './component/Contact/Contact';
import Career from './component/Career/Career';
import Urban from './component/Urban/Urban';
import Infra from './component/Infra/Infra';
import Industry from './component/Industry/Industry';
import AdminPanel from './component/AdminPanel/AdminPanel';
import BlogPanel from './component/AdminPanel/AdminSection/BlogPanel'
import ProductPanel from './component/AdminPanel/AdminSection/ProductPanel';
import LeadPanel from './component/AdminPanel/AdminSection/LeadPanel'
import Panel1 from './component/AdminPanel/AdminSection/Panel1';
import Panel2 from './component/AdminPanel/AdminSection/Panel2';
import ProductPage from './component/Product&services/ProductSection/ProductPage';
import TotalWater from './component/TotalWater/TotalWater';
import SupplyInstallation from './component/SupplyInstallation/SupplyInstallation';
import ZeroLiquid from './component/ZeroLiquid/ZeroLiquid';
import Treatment from './component/TreatmentPlant/Treatment';
import BlogPage from './component/Blog/BlogSection/BlogPage';
import ScrollTop from './component/ScrollTop/ScrollTop';

const App = () => {

  return (
     <Router>
      <ScrollTop/>
      <Navbar/>
     <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/about' element={<About/>} />
      <Route path='/Solutions' element={<Solutions/>} />
      <Route path='/product&services' element={<Product/>} />
      <Route path='/Sectors' element={<Sector/>} />
      <Route path='/blog' element={<Blog/>} />
      <Route path='/contact' element={<Contact/>} />
      <Route path='/career' element={<Career/>} />
      <Route path='/Urban' element={<Urban/>} />
      <Route path='/Infra' element={<Infra/>} />
      <Route path='/Industry' element={<Industry/>} />
      <Route path='/AdminPanel' element={<AdminPanel/>} />
      <Route path='/Blogpanel' element={<BlogPanel/>} />
      <Route path='/Productpanel' element={<ProductPanel/>} />
      <Route path='/Leadpanel' element={<LeadPanel/>} />
      <Route path='/Panel1' element={<Panel1/>} />
      <Route path='/Panel2' element={<Panel2/>} />
      <Route path='/productpage/:id' element={<ProductPage/>} />
      <Route path='/blogpage/:id' element={<BlogPage/>} />
      <Route path='/TotalWaterManagement' element={<TotalWater/>} />
      <Route path='/SupplyInstallation' element={<SupplyInstallation/>} />
      <Route path='/Zeroliquid' element={<ZeroLiquid/>} />
      <Route path='/Treatmentplant' element={<Treatment/>} />
    </Routes>
    <Footer/>
  </Router>
 
  )
}

export default App