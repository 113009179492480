import React from 'react'
import '../Contact.css'

const ContactSection = () => {
  return (
    <div className='container p-5'>
    <div className='row mt-5'>
    <div className='col-md-6 col-sm-12'>
    <img className="contact_image" src='/contactimg.webp'  alt='hh'/>
    </div>
    <div className='col-md-6 col-sm-12 p-5'>
    <div className='d-flex'><img src='/searchimg.webp' className='contactsearch' alt='hh'/><h3 className='contacthead'>Corporate Office</h3></div>
    <p className='contacttext'>Enhanced WAPP Systems (I) Pvt Ltd. 914/B, 9th Floor Park Centra, NH 8 Sector 30,Gurgaon, Haryana - 122001 <br/>Tel: +97178 40888</p>
    <p className='contacttext'>E-mail: <span className='contacttext1'>Marketing@wappsys.com</span></p>
    </div>
   
    </div>
</div>
  )
}

export default ContactSection