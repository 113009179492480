import React from 'react'
import '../../Solutions/Solution.css'

const services = [
  {
  head:"Environmental Protection:",
  text:" ETPs mitigate the adverse environmental impact of industrial activities by treating wastewater to remove harmful pollutants, preventing contamination of water bodies and soil."
  },
  {
    head:"Compliance with Regulations: ",
    text:"Compliance with local and international environmental regulations is imperative for industries. ETPs enable businesses to meet regulatory standards and avoid penalties associated with non-compliance."
    },
    {
      head:"Resource Conservation:",
      text:" ETPs facilitate the recovery of valuable resources from wastewater, such as water, nutrients, and energy, promoting sustainable resource management practices."
      },
      {
        head:"Community Health and Safety:",
        text:" By treating wastewater effectively, ETPs contribute to safeguarding public health and ensuring the safety of communities residing near industrial facilities."
        }
];

const TreatmentSection3 = () => {
  return (
    <div className='containers1'>
    <div className="d-flex justify-content-center">
         <h2 className="homeabouthead mt-3 ">Benefits of 
           <span className="homeabouthead1 "> ETP:</span>
         </h2>
         </div>
         <div className=''>
   
             {services.map((service, index) => (
               <div className='liback' key={index}>
                 <li className="approachhead">{service.head}<span className="approachtext"> {service.text}</span></li>
               </div>
             ))}
         </div>
       </div>
  )
}

export default TreatmentSection3