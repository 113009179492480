import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGreaterThan } from '@fortawesome/free-solid-svg-icons';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

const ProductCard = ({ product }) => (
    <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
      <div className="prohomecard">
        <div className="prohomecardinner">
          {product.files.length > 0 && (
            <img  className="prohomecardimg"  src={`${process.env.REACT_APP_API_URL}/uploads/${product.files[0]}`} alt={`Product 1`} />
          )}
         
            <img src='/servlogo.webp' className="servlogo" alt="Service Logo" /> 
          
        </div>
        <div className="card-body">
          <div className='prohome_textdiv'>
            <h5 className="servtitle">{product.name}</h5>
            <p className="servtext">{product.description}</p>
          </div>
          <div className="promore_div">
            <Link to={`/productpage/${product._id}`} >
              <p className="servanchor"> See More <FontAwesomeIcon icon={faGreaterThan} className="greater-icon ms-2" /></p> 
            </Link>
          </div>
        </div>
      </div>
    </div>
  );

const HomeSection4 = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const fetchProducts = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/products`); 
          if (!response.ok) {   
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setProducts(data);
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      };
      fetchProducts();
    }, []);
  
    const displayedProducts = products.slice(0, 4); 
  
  return (
    <>
    <div className="containers1 homepro_desk_div">
      <h2 className="homeabouthead">Our <span className="homeabouthead1"> Products</span></h2>
      <div className="row">
        {displayedProducts.map(product => (
          <ProductCard key={product._id} product={product} />
        ))}
      </div>
      <div className="d-flex justify-content-center">
        <a href="/product&services" className="servanchor1">View All</a>
      </div>
    </div>
    <div className="containers1 homepro_mob_div">
      <h2 className="homeabouthead">Our <span className="homeabouthead1">Products</span></h2>
      <div className="row">
        <Swiper modules={[Autoplay]} spaceBetween={50} slidesPerView={1} loop={true} autoplay={{ delay: 3000, disableOnInteraction: false }} >
          {displayedProducts.map(product => (
            <SwiperSlide key={product._id}>
            <ProductCard product={product} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="d-flex justify-content-center">
        <a href="/product&services" className="servanchor1">View All</a>
      </div>
    </div>
  </>
  )
}

export default HomeSection4