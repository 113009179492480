import React from 'react'
import Sector1 from './SectorSection/Sector1'
import Sector2 from './SectorSection/Sector2'
import Sector3 from './SectorSection/Sector3'


const Sector = () => {
  return (
   <>
   <Sector1/>
   <Sector2/>
   <Sector3/>
   </>
  )
}

export default Sector