import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGreaterThan } from '@fortawesome/free-solid-svg-icons';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

const ServiceCard = ({ imgSrc, logoSrc, title, text }) => (
    <div className="servcard">
      <div className="abt_wrap">
        <img  className="servcardimg" src={imgSrc} alt="service" />
        <img src={logoSrc} className="servlogo" alt="logo" />
      </div>
      <div className="card-body">
        <div className="service_textdiv">
          <h5 className="servtitle">{title}</h5>
          <p className="servtext">{text}</p>
        </div>
        <div className="servmore_div">
          <a href="/product&services">
            <p className="servanchor">See More <FontAwesomeIcon icon={faGreaterThan} className="greartericon ms-2" /></p>
          </a>
        </div>
      </div>
    </div>
  );

const services = [
    {
      imgSrc: '/serviceimg1.webp',
      logoSrc: '/servlogo.webp',
      title: 'Consulting & Assessment',
      text: 'Conducting thorough assessments of client water requirements and resource consumption to ensure compliance with environmental regulations.',
    },
    {
      imgSrc: '/serviceimg2.webp',
      logoSrc: '/servlogo.webp',
      title: 'Smart & Green Technologies',
      text: 'Crafting a spectrum of solutions meticulously designed to meet the specific needs of individual customers.',
    },
    {
      imgSrc: '/serviceimg3.webp',
      logoSrc: '/servlogo.webp',
      title: 'Operation & Maintenance',
      text: 'Providing continuous operational excellence through regular support and maintenance services.',
    },
    {
      imgSrc: '/serviceimg4.webp',
      logoSrc: '/servlogo.webp',
      title: 'Water Neutrality roadmap',
      text: 'Wastewater treatment and its reuse can significantly reduce freshwater demand by providing an alternative water source for irrigation, industrial processes, and even potable use.',
    },
  ];

const HomeSection5 = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
      };
  
      handleResize();
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  return (
    <div className="containers1">
    <div className="row d-flex">
      <div className="col-lg-3 col-md-5 col-sm-4">
        <h2 className="homeabouthead">Our <span className="homeabouthead1">Services</span></h2>
      </div>
    </div>
    <div className="row d-flex justify-content-between mt-3">
      {isMobile ? (
        <Swiper
          modules={[Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          loop={true}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
        >
          {services.map((service, index) => (
            <SwiperSlide key={index}>
              <ServiceCard 
                imgSrc={service.imgSrc} 
                logoSrc={service.logoSrc} 
                title={service.title} 
                text={service.text} 
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        services.map((service, index) => (
          <div className="col-lg-3 col-md-6 col-sm-12 desk_servcard_div" key={index}>
            <ServiceCard 
              imgSrc={service.imgSrc} 
              logoSrc={service.logoSrc} 
              title={service.title} 
              text={service.text} 
            />
          </div>
        ))
      )}
    </div>
    <div className="d-flex justify-content-center mt-3">
      <a href="/product&services" className="servanchor1 mt-lg-5 mb-5">View All</a>
    </div>
  </div>
  )
}

export default HomeSection5