import React from 'react'

const CareerSection1 = () => {
  return (
    <div className='containers2'>
    <h4 className='career_head mt-5'>Join Us to Build a <span className='carrer_colorhead'>Sustainable Environment for Everyone</span></h4>
    <div className='carrer_text_div'>
    <p className='career_text'>For a work culture that promotes team spirit and nurtures collective growth through shared goals. For a work culture that promotes team spirit and nurtures collective growth through shared goals.</p>
    </div>
    <h4 className='career_head mt-5'>Offering Water <span className='carrer_colorhead'>Sustainability Careers</span> </h4>
    <div className='carrer_text_div'>
    <p className='career_text'>We are a professionally managed, Equal Opportunities employer with a commitment to “Ensure Sustainable Future”. Working with us will offer you a wealth of career avenues as well as cultural and cross-functional exposure to power your dreams.</p>

    <h4 className='career_linkhead mt-3'>Mail us your CV at - <a href='mailto:hr@wappsys.com'><span className='career_colorlink'>hr@wappsys.com</span></a></h4>
    <h4 className='career_linkhead mt-3'>For further Updation on our Open Positions, Stay updated on our <a href='https://www.linkedin.com/company/wappsys-india-water-managment/'><span className='career_colorlink'> LinkedIn Page.</span></a></h4>
    </div>
</div>
  )
}

export default CareerSection1