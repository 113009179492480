import React from 'react'
import AboutHero from './AboutSection/AboutHero'
import AboutSection1 from './AboutSection/AboutSection1'
import AboutSection2 from './AboutSection/AboutSection2'
import AboutLeadership from './AboutSection/AboutLeadership'
import OurClient from './AboutSection/OurClient'
import Testimonials from './AboutSection/Testimonials'

const About = () => {
  return (
    <div>
        <AboutHero/>
        <AboutSection1/>
        <AboutSection2/>
        <AboutLeadership/>
        <OurClient/>
        <Testimonials/>
    </div>
  )
}

export default About