import React from 'react'
import '../Home.css';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

const HomeSolution = () => {
    const solutions = [
        {
            img: '/solutionimg1.webp',
            title: 'Total Water Management',
            text: 'Total water management is essential for sustainable development, ensuring water security, ecosystem preservation, and equitable access, thereby enhancing ESG ratings and corporate reputation.',
            link: '/TotalWaterManagement'
        },
        {
            img: '/solutionimg2.webp',
            title: 'Supply Installation Testing & Comissioning',
            text: 'At Enhanced WAPP, we deliver comprehensive SITC solutions for advanced STP systems, ensuring efficient sewage treatment, environmental compliance, and sustainable resource management.',
            link: '/SupplyInstallation'
        },
        {
            img: '/solutionimg3.webp',
            title: 'Zero Liquid Discharge',
            text: 'We offer Zero Liquid Discharge (ZLD) systems that fully recycle wastewater, eliminating discharge and enhancing sustainable water practices.',
            link: '/Zeroliquid'
        },
        {
            img: '/solutionimg4.webp',
            title: 'Effluent Treatment Plant',
            text: 'An Effluent Treatment Plant (ETP) is essential for modern industry, purifying wastewater to ensure environmental sustainability and regulatory compliance before discharge.',
            link: '/Treatmentplant'
        }
    ];

  return (
    <div className='containers1'>
    <h2 className="homeabouthead">
        Our <span className="homeabouthead1"> Solutions</span>
    </h2>
    <div className='row sol-desk_div mt-3'>
        {solutions.map(solution => (
            <div key={solution.link} className='col-lg-3 col-md-6 col-sm-12'>
                <a href={solution.link}>
                    <div className='solution_card'>
                        <div className='sol_card_front'>
                            <img className='sol_img' src={solution.img} alt={solution.title} />
                            <div className='sol_text_div'>
                                <h4 className='sol_head'>{solution.title}</h4>
                            </div>
                        </div>
                        <div className='sol_card_back'>
                            <img className='sol_img' src={solution.img} alt={solution.title} />
                            <div className='sol_cont_div'>
                                <p className='sol_text'>{solution.text}</p>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        ))}
    </div>
    <div className='row sol_mob_div'>
        <Swiper
            modules={[Autoplay]}
            spaceBetween={50}
            slidesPerView={1}
            loop={true}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
        >
            {solutions.map(solution => (
                <SwiperSlide key={solution.link}>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                        <div className='solution_card'>
                            <div className='sol_card_front'>
                                <img className='sol_img' src={solution.img}  alt={solution.title} />
                                <div className='sol_text_div'>
                                    <h4 className='sol_head'>{solution.title}</h4>
                                </div>
                            </div>
                            <div className='sol_card_back'>
                                <img className='sol_img' src={solution.img} alt={solution.title} />
                                <div className='sol_cont_div'>
                                    <p className='sol_text'>{solution.text}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    </div>
</div>
  )
}

export default HomeSolution