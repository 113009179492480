import { useState, useEffect } from 'react';
import axios from 'axios';
import "../Contact.css";


const ContactForm = () => {
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({ title: '', content: '' });
  
    const openModal = (item) => {
      setSelectedItem(item);
      setShowModal(true);
    };
  
    const closeModal = () => {
      setShowModal(false);
      setSelectedItem({ title: '', content: '' });
      resetForm();
    };
  
    const resetForm = () => {
      setName('');
      setCompany('');
      setPhone('');
      setEmail('');
      setMessage('');
    };
  
    useEffect(() => {
      async function fetchData() {
        try {
          const apiUrl = `${process.env.REACT_APP_API_URL}/contacts`; 
          const response = await axios.get(apiUrl);
          // Handle the fetched data if needed
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    
      fetchData();
    }, []);
    
    async function createNewContact(ev) {
      ev.preventDefault();
  
      const data = {
        name,
        company,
        phone,
        email,
        message
      };
  
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/contacts`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (response.status === 200) {
          openModal(details.message);
        }
      } catch (error) {
        console.error('Error creating contact:', error);
      }
    }
    
    const details = {
      message: {
        title: 'Thanks for the Query',
        content: 'We will revert you back as soon as possible.'
      }
    };
  return (
    <div className='container mt-3'>
    <div className='contactborder'>
      <h2 className="formhead mt-4">Send Us Your Query</h2>
      <form onSubmit={createNewContact}>
        <div className="containers1">   
          <div className="d-flex justify-content-between">
            <input 
              type="text" 
              placeholder="Name" 
              className="contactinput" 
              value={name} 
              onChange={ev => setName(ev.target.value)} 
            />
            <input 
              type="text" 
              placeholder="Company" 
              className="contactinput" 
              value={company} 
              onChange={ev => setCompany(ev.target.value)} 
            />
          </div>
          <div className="d-flex justify-content-between mt-3">
            <input 
              type="tel" 
              placeholder="Phone" 
              className="contactinput" 
              value={phone} 
              onChange={ev => setPhone(ev.target.value)} 
            />
            <input 
              type="email" 
              placeholder="Email" 
              className="contactinput" 
              value={email} 
              onChange={ev => setEmail(ev.target.value)} 
              required
            />
          </div>
          <textarea 
            placeholder="Message" 
            className="contacttextarea mt-3" 
            value={message} 
            onChange={ev => setMessage(ev.target.value)} 
          /><br/>
          <button type="submit" className="contactbutt mt-2" onClick={() => openModal(details.message)}>Send Message</button>
        </div>
      </form>
    </div>

    {showModal && (
      <div className='modal'>
        <div className='modal-content'>
          <span className='close' onClick={closeModal}>&times;</span>
          <h2 className='message-head'>{selectedItem.title}</h2>
          <p className='message_text'>{selectedItem.content}</p>
        </div>
      </div>
    )}
  </div>
  )
}

export default ContactForm