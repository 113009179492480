import React from 'react'
import '../Solution.css'
import '../../About/About.css'

const SolutionSection2 = () => {
  return (
    <div className="container mt-5 mb-3">
    <div className="row">
    <div className="col-lg-3 col-md-6 mb-4">
       <a href='/TotalWaterManagement' className='no-underline'> <div className='solutioncard'>
       <div className='solutioncard'>
<div className="cont">
<img src="/Solutionimg1.webp" alt="Avatar" className="solutionimage"/>
<div className="overlay">
<div className="text1">Water is at the core of sustainable development given that it is critical for socio-economic development, healthy ecosystems, and human survival.</div>
</div>
</div>
<div className='cardbottom d-flex align-items-center justify-content-center'>
    <p className='cardtext'>Total Water Management</p>
</div>
</div>
</div></a>
    </div>
    <div className="col-lg-3 col-md-6 mb-4">
        <a href='/SupplyInstallation'>
        <div className='solutioncard'>
<div className="cont">
<img src="/Solutionimg2.webp" alt="Avatar" className="solutionimage"/>
<div className="overlay">
<div className="text1">At Enhanced WAPP, we specialize in providing comprehensive solutions for the Supply, Installation, Testing, and Commissioning (SITC) of a wide range of Sewage Treatment Plant (STP) systems</div>
</div>
</div>
<div className='cardbottom d-flex align-items-center justify-content-center'>
    <p className='cardtext'>Supply Installation Testing & Comissioning</p>
</div>
</div>
</a>
    </div>
    <div className="col-lg-3 col-md-6 mb-4">
        <a href='/Zeroliquid'>
        <div className='solutioncard'>
<div className="cont">
<img src="/Solutionimg3.webp" alt="Avatar" className="solutionimage"/>
<div className="overlay">
<div className="text1">We help you reuse all sources of waste water in your premises to cut your water bill and meet your compliances.</div>
</div>
</div>
<div className='cardbottom  d-flex align-items-center justify-content-center'>
    <p className='cardtext'>Zero Liquid Discharge</p>
</div>
</div>
</a>
    </div>
    <div className="col-lg-3 col-md-6 mb-4">
        <a href='/Treatmentplant'>
        <div className='solutioncard'>
<div className="cont">
<img src="/Solutionimg4.webp" alt="Avatar" className="solutionimage"/>
<div className="overlay">
<div className="text1">An Effluent Treatment Plant (ETP) is a crucial component of modern industrial infrastructure designed to manage and treat wastewater produced during industrial processes</div>
</div>
</div>
<div className='cardbottom d-flex align-items-center justify-content-center'>
    <p className='cardtext'>Effluent Treatment Plant</p>
</div>
</div>
</a>
    </div>
    </div>
</div>
  )
}

export default SolutionSection2