import React from 'react'

const UrbanHero = () => {
  return (
    <div className='urbanback commonback '>
    <h1 className='sechead'>URBAN</h1>
    </div>
  )
}

export default UrbanHero