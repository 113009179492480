import React from 'react'
import ZeroSection1 from './ZeroSection/ZeroSection1'
import ZeroSection2 from './ZeroSection/ZeroSection2'
import ZeroSection3 from './ZeroSection/ZeroSection3'
import TotalSection4 from '../TotalWater/TotalSection/TotalSection4'

const ZeroLiquid = () => {
  return (
    <div>
        <ZeroSection1/>
        <ZeroSection2/>
        <ZeroSection3/>
        <TotalSection4/>
    </div>
  )
}

export default ZeroLiquid