import React from 'react'
import '../Zero.css'

const services = [
    {
    head:"Aquatron:",
    text:"Affordable zero liquid discharge solution without evaporator ensuring no harm to the environment & Offers a low TCO and cost of operation."
    },
    {
      head:"Multiple Effect Evaporation (MEE):",
      text:"Utilizes multiple stages of evaporation to concentrate and reduce the volume of wastewater. Reuse of vapor heat across stages, leading to significant operational cost savings."
      },
      {
        head:"Mechanical Vapor Recompression (MVR):",
        text:" Recycles the vapor produced during the evaporation process by compressing it to higher pressure and temperature. Offers substantial energy savings and operational efficiency, making it a preferred choice for modern ZLD systems."
        }
  ];

const ZeroSection3 = () => {
  return (
    <div className="containers1">
    <div className="d-flex justify-content-center">
    <h2 className="homeabouthead mt-3 ">ZLD
      <span className="homeabouthead1 "> Systems:</span>
    </h2>
    </div>
    <div className=''>

        {services.map((service, index) => (
          <div className='liback' key={index}>
            <li className="approachhead">{service.head}<span className="approachtext"> {service.text}</span></li>
          </div>
        ))}
    </div>

  </div>
  )
}

export default ZeroSection3