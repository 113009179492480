import React from 'react'
import '../Solution.css';
import '../../About/About.css';

const SolutionSection1 = () => {
  return (
    <div className='containers2'>
    <h1 className='abouthead1 mt-5'>Water<span className='colouredhead'>-Innovision</span></h1>
    <p className='abouttext'>At WAPP, we&apos;re committed to a sustainable future rooted in the wisdom of the five elements. We specialize in innovative water management, emphasizing decentralization for clean, green development.</p>
    <p className='abouttext'>Our expertise lies in connecting infrastructure to people&apos;s needs using advanced remote sensing and data analytics, enhancing project efficiency and lifecycle. Explore our global solutions for urban, rural, and industrial water needs to build a better, safer future for generations to come.</p>
    <h3 className='abouthead1 mt-3'>Our  <span className='colouredhead'>Solutions</span></h3>
    <p className='abouttext'>WAPP is working towards building a modern, healthier and sustainable world. We use our expertise, experience, and passion to provide innovative, integrated solutions in the field of water, waste, energy, and environment across urban, industrial and rural markets. With solutions, that are environmentally viable and sustainable, we balance progress with technology that helps restore and regenerate our earth.</p>
  </div>
  )
}

export default SolutionSection1