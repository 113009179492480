import React from 'react'
import '../../Solutions/Solution.css'

const contentItems = [
    {
     item: "01",
      head: "Primary Treatment",
      text: "The process begins with the removal of large solids through physical methods such as screening and sedimentation. This step helps in reducing the load on subsequent treatment stages"
    },
    {
      item: "02",
      head: "Secondary Treatment ",
      text: "In this phase, biological processes like activated sludge process or aerobic/anaerobic digestion are employed to break down organic pollutants present in the wastewater. Microorganisms are utilized to decompose organic matter, converting it into harmless byproducts like carbon dioxide and water."
    },
    {
      item: "03",
      head: "Tertiary Treatment",
      text: "Advanced treatment methods such as filtration, chemical precipitation, and disinfection are employed to further refine the effluent and remove any remaining contaminants. This stage ensures that the treated wastewater meets stringent environmental quality standards."
    }
  ];

const TreatmantSection2 = () => {
  return (
    <>

    <div className='container adv_service_desk'>
      
        <div className='row mt-4'>
        <div className='  col-2'>
        {contentItems.map((content, index) => (
           <div key={index} className='list mb-4'>
            <img className='list_img' src="/sollist.webp" alt='sol'/>
            <div className='overlay_item'>
              <p className='listtext'>{content.item}</p>
           </div>
           </div>
          ))}
           </div>
           <div className=' col-10'>
          {contentItems.map((content, index) => (
           <div key={index}>
            <div className='list1 mb-4'>
              <h1 className='listhead'>{content.head}</h1>
              <p className='listtext1'>{content.text}</p>
           </div>
           </div>
          ))}
        </div>
        </div>
    </div>


    <div className='adv_service_mob'>

{contentItems.map((content, index) => (
            <div key={index}>
              <div className='adv_mob_head '>
              <p className='listtext'>{content.item}</p>
              <h1 className='listhead'>{content.head}</h1>
              </div>
              <p className='listtext1'>{content.text}</p>
            </div>
          ))}
</div>


 
    </>
  )
}

export default TreatmantSection2