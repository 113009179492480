import React from 'react'
import ProductSection1 from './ProductSection/ProductSection1'
import ProductSection2 from './ProductSection/ProductSection2'
import ServiceSection from './ProductSection/ServiceSection'
import OurClient from '../About/AboutSection/OurClient'

const Product = () => {
  return (
    <>
    <ProductSection1/>
    <ProductSection2/>
    <ServiceSection/>
    <OurClient/>
    </>
  )
}

export default Product