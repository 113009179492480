import React from 'react'
import SupplySection1 from './SupplySection/SupplySection1'
import SupplySection2 from './SupplySection/SupplySection2'
import TotalSection4 from '../TotalWater/TotalSection/TotalSection4'

const SupplyInstallation = () => {
  return (
    <div>
      <SupplySection1/>
      <SupplySection2/>
      <TotalSection4/>
    </div>
  )
}

export default SupplyInstallation