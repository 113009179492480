import { useEffect, useState } from 'react';
import '../AdminPanel.css';
import Sidebar from './Sidebar';
import Panel2 from './Panel2';

const ProductPanel = () => {
  const [showModal, setShowModal] = useState(false);
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 4;

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/products`);   
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setPosts(data);
      } catch (error) {
        setError(error.message);
      }
    };
  
    fetchPosts();
  }, []);
  
  const sortedPosts = posts.slice().reverse(); 
  
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = sortedPosts.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const deletePost = async (postId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/Products?id=${postId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
     
      setPosts(posts.filter((post) => post._id !== postId));
    } catch (error) {
      setError(error.message);
    }
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(posts.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const maxPageNumberToShow = 2;
  const startPageNumber = Math.max(currentPage - 1, 1);
  const endPageNumber = Math.min(startPageNumber + maxPageNumberToShow - 1, pageNumbers.length);
  return (
    <div>
     <Sidebar/>
      <div className='main-content'>
        <div className='button-container'>
          <button className='panelbutt' onClick={openModal}>Add Products</button>
        </div>
        <div>
          <h2 className='adminhead'>Manage Products</h2>
          {error && <p>Error: {error}</p>}
          <div className='d-flex justify-content-center'>
            <table>
              <thead>
                <tr>
                  <th className='sn_div'>SN</th>
                  <th className='title_div'>Title</th>
                  <th className='action_div'>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentPosts.map((post, index) => (
                  <tr key={post._id}>
                    <td>{indexOfFirstPost + index + 1}</td>
                    <td>{post.name}</td>
                    <td>
                      <button
                        className='dlt_btn'
                        onClick={() => deletePost(post._id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='pagination'>
            {currentPage > 1 && (
              <button  className='prev_pagination' onClick={() => paginate(currentPage - 1)}>
                Previous
              </button>
            )}
            {pageNumbers.slice(startPageNumber - 1, endPageNumber).map(number => (
              <button
                key={number}
                onClick={() => paginate(number)}
               className='paginate_btn'
              >
                {number}
              </button>
            ))}
            {currentPage < pageNumbers.length && (
              <button  className='prev_pagination' onClick={() => paginate(currentPage + 1)}>
                Next
              </button>
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <div className="modal">
          <div className="modal-admincontent">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
     <Panel2/>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProductPanel