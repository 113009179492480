import { useEffect, useState } from 'react';
import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGreaterThan } from '@fortawesome/free-solid-svg-icons';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import '../Product.css'

const ProductSection2 = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const fetchProducts = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/products`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setProducts(data);
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchProducts();
    }, []);
  
   
  return (
    <div className='productbackcolor'>
    <div className="containers1 procard_desk_div">
      <h2 className="homeabouthead">Our <span className="homeabouthead1"> Products</span></h2>
      <div className="row mt-3">
        {products.map(product => (
          <div key={product._id} className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="productcard">
              <div className="abt_wrap">
                {product.files.length > 0 && (
                  <img className="procardimg"  src={`${process.env.REACT_APP_API_URL}/uploads/${product.files[0]}`} alt={`Product 1`} />
                )}
                
                  <img src='/servlogo.webp' className="servlogo" alt="Service Logo" />
               
              </div>
              <div className="product_textdiv">
                <h5 className="servtitle">{product.name}</h5>
                <p className="servtext">{product.description}</p>
              </div>
              <div className="d-flex justify-content-center align-items-center product_seediv">
              <Link to={`/productpage/${product._id}`}>
                  <p className="servanchor"> See More <FontAwesomeIcon icon={faGreaterThan} className="greater-icon" /></p>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>

    <div className="containers1 procard_mob_div">
      <h2 className="homeabouthead">Our <span className="homeabouthead1"> Products</span></h2>
      <div className="row mt-3">
        <Swiper
          modules={[Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          loop={true}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
        >
          {products.map(product => (
            <SwiperSlide key={product._id}>
              <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                <div className="productcard">
                  <div className="relative">
                    {product.files.length > 0 && (
                      <img className="procardimg" src={`${process.env.REACT_APP_API_URL}/uploads/${product.files[0]}`} alt={`Product 1`} />
                    )}
                    
                      <img src='/servlogo.webp' className="servlogo" alt="Service Logo" />
                  
                  </div>
                  <div className="product_textdiv">
                    <h5 className="servtitle">{product.name}</h5>
                    <p className="servtext">{product.description}</p>
                  </div>
                  <div className="d-flex justify-content-center align-items-center product_seediv">
                  <Link to={`/productpage/${product._id}`}>
                      <p className="servanchor"> See More <FontAwesomeIcon icon={faGreaterThan} className="greater-icon" /></p>
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  </div>
  )
}

export default ProductSection2