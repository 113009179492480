import  { useEffect, useState } from 'react';
import {Link}  from 'react-router-dom';
import "../Blog.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGreaterThan } from '@fortawesome/free-solid-svg-icons';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

const BlogSection1 = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 6;

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/posts`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setPosts(data.reverse());
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchPosts();
    }, []);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const nextPage = () => {
        setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(posts.length / postsPerPage)));
    };

    const prevPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    };

   

    
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <>
    <div className="containers1 blog_container_desk">
        <div className='blog-container'>
            {currentPosts.map((post) => (
                <div key={post._id} className='blogcards'>
                    {post.files && post.files.length > 0 && (
                        <div className="blogcard_img">
                            <img 
                                src={`${process.env.REACT_APP_API_URL}/uploads/${post.files[0]}`} 
                                alt={`blog`} 
                                className="post-image" 
                            />
                        </div>
                    )}
                    <div className='blogcard_text_div'>
                        <h2 className='blog_head'>{post.name}</h2>
                        <Link to={`/blogpage/${post._id}`}>
                            <p className='blog_read'>Read More <FontAwesomeIcon icon={faGreaterThan} className="more-icon ms-1" /></p>
                        </Link>
                    </div>
                </div>
            ))}
        </div>
        
        <div className='pagination mt-5'>
            <button 
                onClick={prevPage} 
                disabled={currentPage === 1}
                className="pagination-button"
            >
                Previous
            </button>
            {Array.from({ length: Math.ceil(posts.length / postsPerPage) }, (_, i) => (
                <button 
                    key={i + 1} 
                    onClick={() => paginate(i + 1)} 
                    className={`page-button ${currentPage === i + 1 ? 'active' : ''}`}
                >
                    {i + 1}
                </button>
            ))}
            <button 
                onClick={nextPage} 
                disabled={currentPage === Math.ceil(posts.length / postsPerPage)}
                className="pagination-button"
            >
                Next
            </button>
        </div>
    </div>

    <div className='containers1 blog_container_mob'>
        <Swiper
            modules={[Autoplay]}
            spaceBetween={300}
            slidesPerView={1}
            loop={true}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
        >
            {currentPosts.map((post) => (
                <SwiperSlide key={post._id}>
                    <div className='blogcards mt-5 mb-5'>
                        {post.files && post.files.length > 0 && (
                            <div className="blogcard_img">
                                <img 
                                    src={`${process.env.REACT_APP_API_URL}/uploads/${post.files[0]}`} 
                                    alt={`blog`} 
                                    className="post-image" 
                                />
                            </div>
                        )}
                        <div className='blogcard_text_div'>
                            <h2 className='blog_head'>{post.name}</h2>
                            <Link to={`/blogpage/${post._id}`}>
                                <p className='blog_read'>Read More <FontAwesomeIcon icon={faGreaterThan} className="more-icon ms-1" /></p>
                            </Link>
                        </div>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    </div>
</>
  )
}

export default BlogSection1