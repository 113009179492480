import React from 'react'
import '../../Solutions/Solution.css'

const services = [
    {
    head:"Customized Solutions:",
    text:" We collaborate closely with clients to grasp their unique needs and site conditions, enabling us to deliver customized STP solutions that align with regulatory standards."
    },
    {
      head:"Quality Assurance:",
      text:"From procurement to installation and testing, we maintain stringent quality control measures to ensure the reliability, efficiency, and longevity of our STP systems"
      },
      {
        head:"Technical Expertise:",
        text:"Our skilled engineers and technicians bring extensive expertise in wastewater treatment technologies and best practices, ensuring we provide optimal solutions and support services to our clients."
        }
  ];
  
  const qaServices = [
    "Visits",
    "Testing quality",
    "Process Audit",
    "Recommendation",
    "Trouble Shooting",
  ];

const TotalSection3 = () => {
  return (
    <div className="containers1">
    <div className="d-flex justify-content-center">
    <h2 className="homeabouthead mt-3 ">Our
      <span className="homeabouthead1 "> Approach:</span>
    </h2>
    </div>
    <div className=''>

        {services.map((service, index) => (
          <div className='liback' key={index}>
            <li className="approachhead">{service.head}<span className="approachtext"> {service.text}</span></li>
          </div>
        ))}
    </div>
    <h2 className="homeabouthead mt-5 d-flex justify-content-center mb-3">
      WQA<span className="homeabouthead1">+TS</span>
    </h2>
    <div className=" mt-5 mb-5 d-md-flex flex-wrap justify-content-between">
      {qaServices.map((service, index) => (
        <div className="d-flex align-items-center justify-content-center" key={index}>
          <img src="/tickimg.webp" className="tick me-3" alt="tck" />
          <p className="Wqatext">{service}</p>
        </div>
      ))}
    </div>
  </div>
  )
}

export default TotalSection3