import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import 'swiper/css/effect-cube';
import 'swiper/css/effect-fade';
import 'swiper/css/effect-flip';
import 'swiper/css/effect-cards';
import { Navigation, Autoplay, FreeMode, EffectCoverflow, EffectCube, EffectFade, EffectFlip, EffectCards } from 'swiper/modules';

const ClientEntry = ({ imgSrc }) => (
    <div className="d-flex justify-content-between">
      <img  src={imgSrc} className="clientimg" alt="clientimage" />
    </div>
  );

const HomeClient = () => {
    const client = [
        { imgSrc: "/Britannia.webp" },
        { imgSrc: "/Coca-Cola.webp" },
        { imgSrc: "/dlf.webp" },
        { imgSrc: "/emami.webp" },
        { imgSrc: "/eros.webp" },
        { imgSrc: "/Escorts.webp" },
        { imgSrc: "/fortis-hospitals.webp" },
        { imgSrc: "/Hyatt.webp" },
        { imgSrc: "/jaypee.webp" },
      ];
  return (
    <div className='containers1'>
    <div className="col-md-5 col-sm-4 col-lg-3 mt-3 mb-3">
      <h2 className="homeabouthead">Our <span className="homeabouthead1"> Clients</span></h2>
    </div>
    <Swiper
      modules={[Navigation, Autoplay, FreeMode, EffectCoverflow, EffectCube, EffectFade, EffectFlip, EffectCards]} // Add all necessary Swiper modules here
      slidesPerView={3}
      spaceBetween={20}
      loop={true}
      freeMode={true} 
      autoplay={{
        delay: 10,
        disableOnInteraction: false, 
      }}
      speed={1000}
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }}
      pagination={{ clickable: true }}
      lazy="true"
      breakpoints={{
        1024: {
          slidesPerView: 7,
        },
        768: {
          slidesPerView: 4,
        },
      }}
    >
      {client.map((entry, index) => (
        <SwiperSlide key={index}>
          <ClientEntry imgSrc={entry.imgSrc} />
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
  )
}

export default HomeClient