import { useEffect, useState } from 'react';
import '../AdminPanel.css';
import Sidebar from './Sidebar';


const LeadPanel = () => {
    const [posts, setPosts] = useState([]);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentRange, setCurrentRange] = useState([1, 2]);
    const postsPerPage = 6;
  
    useEffect(() => {
      const fetchPosts = async () => {
        try {
          const response = await fetch( `${process.env.REACT_APP_API_URL}/contacts`);   
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setPosts(data);
        } catch (error) {
          setError(error.message);
        }
      };
    
      fetchPosts();
    }, []);
  
    const deletePost = async (postId) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/contacts?id=${postId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
       
        setPosts(posts.filter((post) => post._id !== postId));
      } catch (error) {
        setError(error.message);
      }
    };
    
    const sortedPosts = posts.slice().reverse(); 
    
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = sortedPosts.slice(indexOfFirstPost, indexOfLastPost);
  
    const paginate = (pageNumber) => {
      setCurrentPage(pageNumber);
      if (pageNumber > currentRange[1]) {
        setCurrentRange([pageNumber, pageNumber + 1]);
      } else if (pageNumber < currentRange[0]) {
        setCurrentRange([pageNumber - 1, pageNumber]);
      }
    };
  
    const nextPage = () => {
      if (currentRange[1] < Math.ceil(posts.length / postsPerPage)) {
        setCurrentRange([currentRange[0] + 1, currentRange[1] + 1]);
        setCurrentPage(currentRange[0] + 1);
      }
    };
  
    const prevPage = () => {
      if (currentRange[0] > 1) {
        setCurrentRange([currentRange[0] - 1, currentRange[1] - 1]);
        setCurrentPage(currentRange[0] - 1);
      }
    };
  return (
    <>
   <Sidebar/>
    <div>
    <div className='main-content'>
      <div>
        <h2 className='adminhead'>Manage Leads</h2>
        {error && <p>Error: {error}</p>}
        <div className='d-flex justify-content-center'>
          <table>
            <thead>
              <tr>
                <th>SN</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Company</th>
                <th>Email</th>
                <th>Message</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentPosts.map((post, index) => (
                <tr key={post._id}>
                  <td>{indexOfFirstPost + index + 1}</td>
                  <td>{post.name}</td>
                  <td>{post.phone}</td> 
                  <td>{post.company}</td>
                  <td>{post.email}</td>
                  <td>{post.message}</td>
                  <td>
                    <button   className='dlt_btn'
                     onClick={() => deletePost(post._id)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='pagination mt-5'>
          <button className='prev_pagination' onClick={prevPage} disabled={currentRange[0] === 1}>Previous</button>
          {[...Array(Math.ceil(posts.length / postsPerPage)).keys()]
            .slice(currentRange[0] - 1, currentRange[1])
            .map(number => (
              <button className='paginate_btn' key={number + 1} onClick={() => paginate(number + 1)}>
                {number + 1}
              </button>
            ))}
          <button className='prev_pagination' onClick={nextPage} disabled={currentRange[1] >= Math.ceil(posts.length / postsPerPage)}>Next</button>
        </div>
      </div>
    </div>
    </div>
  </>
  )
}

export default LeadPanel