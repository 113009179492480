import React from 'react'
import '../Supply.css'

const SupplySection1 = () => {
  return (
    <>
    <div className='supplyback'>
  <h4 className='supply_title'>Supply Installation Testing & Comissioning</h4>
    </div>
    <div className='containers1'>
      <p className='supplytext'>At Enhanced WAPP, we specialize in providing comprehensive solutions for the Supply, Installation, Testing, and Commissioning (SITC) of a wide range of Sewage Treatment Plant (STP) systems. Our expertise encompasses various cutting-edge technologies designed to efficiently treat sewage and wastewater, ensuring environmental compliance, and promoting sustainable resource management practices.</p>
    </div>
    </>
  )
}

export default SupplySection1