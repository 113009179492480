import React from 'react'
import '../Career.css'

const CareerHero = () => {
  return (
    <div className='careerback'>
    <h2 className='career_title'>Career</h2>
</div>
  )
}

export default CareerHero