import React from 'react'
import ContactHero from './ContactSection/ContactHero'
import ContactForm from './ContactSection/ContactForm'
import ContactSection from './ContactSection/ContactSection'

const Contact = () => {
  return (
    <div>
        <ContactHero/>
        <ContactForm/>
        <ContactSection/>
    </div>
  )
}

export default Contact