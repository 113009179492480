import React from 'react'
import '../Supply.css'

const SupplySection2 = () => {
    const contentItems = [
        {
         item: "01",
          head: "Moving Bed Biofilm Reactor (MBBR)",
          text: "MBBR systems utilize a biofilm process to effectively remove organic and inorganic pollutants from wastewater. The system's compact design and high treatment efficiency make it an ideal choice for diverse industrial and municipal applications."
        },
        {
          item: "02",
          head: "Membrane Bioreactor (MBR)",
          text: "MBR technology combines biological treatment with membrane filtration to achieve superior effluent quality and a smaller footprint compared to conventional systems. It is highly effective in removing suspended solids, pathogens, and nutrients from wastewater."
        },
        {
          item: "03",
          head: "Sequential Batch Reactor (SBR)",
          text: "SBR systems operate in batch mode, allowing for flexibility in wastewater treatment operations. They are capable of achieving high levels of nutrient removal and can adapt to fluctuating influent characteristics, making them suitable for decentralized and remote installations."
        },
        {
          item: "04",
          head: "Green Tech Solutions (Grey & Black Water)",
          text: "Our Green Tech solutions encompass innovative approaches to treating both greywater (from sinks, showers, etc.) and blackwater (from toilets) for reuse or safe discharge. These systems contribute to water conservation efforts and promote sustainable water management practices."
        }
      ];
  return (
    <>
    <div className="d-flex justify-content-center">
         <h2 className="homeabouthead mt-3 ">Key systems 
           <span className="homeabouthead1 "> we offer:</span>
         </h2>
         </div>
       <div className='container adv_service_desk mb-5'>
   
           <div className='row mt-4'>
           <div className='  col-1'>
           {contentItems.map((content, index) => (
              <div key={index} className='list mb-5'>
               <img className='list_img' src="/sollist.webp" alt='sol'/>
               <div className='overlay_item'>
                 <p className='listtext'>{content.item}</p>
              </div>
              </div>
             ))}
              </div>
              <div className=' col-11'>
             {contentItems.map((content, index) => (
              <div key={index}>
               <div className='list1 mb-5'>
                 <h1 className='listhead'>{content.head}</h1>
                 <p className='listtext1'>{content.text}</p>
              </div>
              </div>
             ))}
           </div>
           </div>
       </div>
   
   
       <div className='adv_service_mob'>
   
   {contentItems.map((content, index) => (
               <div key={index}>
                 <div className='adv_mob_head '>
                 <p className='listtext'>{content.item}</p>
                 <h1 className='listhead'>{content.head}</h1>
                 </div>
                 <p className='listtext1'>{content.text}</p>
               </div>
             ))}
   </div>
   
   
    
       </>
  )
}

export default SupplySection2